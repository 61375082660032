import React from "react";
import {
  PROVIDER_METAMASK,
  walletInfoDialogToggle,
  walletTypeDialogToggle,
} from "../../../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import {
  getNetworkImageByChainId,
  getNetworkNameByChainId,
  WalletList,
} from "../../../../utils/NetworkList";

function WallectConnectButtonBlock() {
  let dispatch = useDispatch();
  return (
    <button
      className="header__cta btn btn_middle"
      onClick={() => dispatch(walletTypeDialogToggle())}
    >
      Connect wallet
    </button>
  );
}

function WallectConnectedButtonBlock() {
  let dispatch = useDispatch();
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );
  return (
    <>
      <button
        className="widget__wallet-btn"
        onClick={() => dispatch(walletInfoDialogToggle())}
      >
        <img
          src={getNetworkImageByChainId(walletInfo.networkChainId)}
          width="16"
          height="16"
        />
        <span>{`${walletInfo.accountAddress.slice(
          0,
          3
        )}...${walletInfo.accountAddress.slice(-3)}`}</span>
      </button>
    </>
  );
}

function WalletConnectButton() {
  let dispatch = useDispatch();
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  if (!walletInfo.isConnected) {
    return;
  }

  return <WallectConnectedButtonBlock />;
}
export default WalletConnectButton;
