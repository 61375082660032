import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { truncateDecimals } from "../../../../features/walletService/utils";
import {
  disconnect,
  walletDisconnectDialogToggle,
  walletInfoDialogToggle,
} from "../../../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../../../services/metamask/MetamaskWebProvider";
import {
  getNetworkImage,
  getNetworkImageByChainId,
  getWalletServiceImg,
  networkListDat,
  WalletList,
} from "../../../../utils/NetworkList";
import { copyToBuffer } from "../../../../utils/TransferApiUtil";

function WalletInfoDialog() {
  const showWalletInfoPopup = useSelector(
    (state) => state.walletServiceProvider.showWalletInfoPopup
  );
  const walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );
  let dispatch = useDispatch();
  const networkList = networkListDat;
  const [isCopied, setCopied] = useState(false);
  const [balance, setBalance] = useState(0);

  function copyAddress() {
    copyToBuffer(walletInfo.accountAddress);
    setCopied(true);
    // console.log(setCopied,isCopied,'isCopied');
  }

  useEffect(() => {
    loadBalance();
  }, [walletInfo.accountAddress]);

  useEffect(() => {
    loadBalance();
  }, [walletInfo]);

  const loadBalance = async () => {
    if (!walletInfo.accountAddress) return;
    let balance = 0;
    try {
      balance = truncateDecimals(
        await MetamaskWebProvider.getNativeBalance(walletInfo.accountAddress)
      );
    } catch (error) {}
    setBalance(balance);
  };

  function showDisconnectPopup() {
    dispatch(disconnect());
    dispatch(walletInfoDialogToggle());
    // dispatch(walletDisconnectDialogToggle());
  }

  function closeDialog() {
    dispatch(walletInfoDialogToggle());
    setCopied(false);
  }

  return (
    walletInfo.isConnected &&
    showWalletInfoPopup && (
      <>
        <div className="widget-popup wallet-popup">
          <div className="widget-popup__header">
            <button
              className="widget-popup__back-btn"
              onClick={() => closeDialog()}
            >
              Back
            </button>
            <h3 className="widget-popup__title">Wallet</h3>
          </div>
          <div className="wallet-popup__cnt">
            <div className="wallet-popup__logo">
              <picture>
                <img
                  className="wallet-popup__logo-wallet"
                  src={getWalletServiceImg(walletInfo.providerType)}
                  alt=""
                />
              </picture>
              <picture>
                <img
                  className="wallet-popup__logo-network"
                  src={getNetworkImageByChainId(walletInfo.networkChainId)}
                  alt=""
                />
              </picture>
            </div>
            <h1 className="wallet-popup__title popup__title">
              {WalletList.find((v) => v.key == walletInfo.providerType)?.title}
            </h1>
            <div className="wallet-popup__wallet-info">
              <div className="wallet-popup__copy-field">
                <span>
                  {walletInfo.accountAddress.slice(0, 3) +
                    "..." +
                    walletInfo.accountAddress.slice(-3)}
                </span>
                {!isCopied && <button className="copy-btn"></button>}
              </div>
              <div className="wallet-popup__balance">
                <span>
                  {balance}{" "}
                  {
                    networkList.find(
                      (v) => v.chainId == walletInfo.networkChainId
                    )?.currency
                  }
                </span>
                <img src="img/wallet-icon_violet.svg" alt="" />
              </div>
              {isCopied && (
                <span className="wallet-popup__copied">
                  Copied
                  <img src="img/copied.svg" alt="" />
                </span>
              )}
            </div>
            <div className="wallet-popup__actions">
              <button
                className="wallet-popup__disconnect-btn"
                onClick={() => showDisconnectPopup()}
              >
                <img src="img/close-circle.svg" alt="" />
                Disconnect
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
}
export default WalletInfoDialog;
