import { BTN_KEY_APPROVE_RETRY } from "../../constants/SwapButtonConstants";
import {
  STEP_APPROVE_APPROVED,
  STEP_APPROVE_REJECTED,
  STEP_APPROVE_STARTED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import { getTxScanUrl } from "../../utils/NetworkList";

export default function SwapTokenApproveBlock({
  process,
  btnHandler,
  formData,
}) {
  return (
    <>
      {!process.isApproved && (
        <>
          {process.state == STEP_APPROVE_STARTED && (
            <div className="transfers__notification transfers__notification_proccess">
              <span className="loader-blue"></span>
              <span>Token approve required</span>
            </div>
          )}
          {process.state == STEP_APPROVE_REJECTED && (
            <div className="transfers__notification transfers__notification_attention">
              Approve was rejected{" "}
              <a
                className="link"
                href="#"
                onClick={() => btnHandler(BTN_KEY_APPROVE_RETRY)}
              >
                Try one more time
              </a>
            </div>
          )}
        </>
      )}
      {process.isApproved && process.state == STEP_APPROVE_APPROVED && (
        <div className="transfers__notification transfers__notification_proccess">
          <span className="loader-blue"></span>
          <span>
            Token approved successfully. Please wait a minute
            <a
              href={getTxScanUrl(formData.routeFrom.chainId, process.approveTx)}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              network confirmation
            </a>
          </span>
        </div>
      )}
      {process.isApproved && process.state >= STEP_TRANSFER_START && (
        <div className="transfers__notification transfers__notification_success">
          <span>Token fully approved successfully.</span>
        </div>
      )}
    </>
  );
}
