import { createSlice } from '@reduxjs/toolkit'

export const switchNetworkSlice = createSlice({
  name: 'SwitchNetwork',
  initialState: {
    networks: [],
    selectedRoute:null,
    showDialog:false,
    routeChanged:false,
  },
  reducers: {
    showSwitchNetworkDialog: (state,action) => {
      state.networks = action.payload.networks;
      state.selectedRoute = action.payload.selectedRoute;
      state.showDialog = true;
      state.routeChanged = false;
    },
    closeSwitchNetworkDialog: (state,action) => {
      state.showDialog = false
      state.routeChanged = (action.payload && state.selectedRoute != action.payload);
      state.selectedRoute = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { showSwitchNetworkDialog,closeSwitchNetworkDialog } = switchNetworkSlice.actions

export default switchNetworkSlice.reducer
