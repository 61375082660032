import React from "react";
import { Outlet } from "react-router-dom";
import PopupBlock from "./Header/PopupBlock/PopupBlock";

function Layout() {
  return (
    <>
      <PopupBlock />
      <div className={"container "}>
        <Outlet />
      </div>
    </>
  );
}
export default Layout;
