export const localStorageObj ={
    set : (key,providerType) => {
        localStorage.setItem(key, providerType)
    },
    get: (key) => {
        return localStorage.getItem(key)
    },
    remove: (key) =>{
        localStorage.removeItem(key)
    }
} 
