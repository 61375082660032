import { useEffect, useMemo } from "react";
import { CHAINSPOT, LIFI } from "../../constants/ServiceConstants";
import { isZeroAddress } from "../../features/walletService/utils";
import {
  capitalizeFirstLetter,
  getBridgeImageByName,
  getNetworkNameByChainId,
  getTokenImg,
} from "../../utils/NetworkList";
import { getRouteImage, getServiceImage } from "../../utils/TransferApiUtil";

export default function SwapDetail({ formData, transactionData }) {
  const transferTime = useMemo(() => {
    let time = {
      minute: "00",
      second: "00",
    };
    if (transactionData.estimateSeconds) {
      if (transactionData.estimateSeconds > 60) {
        time.minute = parseInt(transactionData.estimateSeconds / 60)
          .toString()
          .padStart(2, "0");
      }
      time.second = (
        transactionData.estimateSeconds > 60
          ? transactionData.estimateSeconds % 60
          : transactionData.estimateSeconds
      )
        .toString()
        .padStart(2, "0");
      if (time.minute == "00") {
        time.minute = "01";
      }
    }
    return time;
  }, [transactionData.estimateSeconds]);

  const difAmountUSD = transactionData.calcFee.amountInUsd.toFixed(2);
  const nativeToken = formData.routeFrom.cryptos.find((v) =>
    isZeroAddress(v.contractAddress)
  );

  useEffect(() => {
    routeLogosContainerHandler();
  }, []);

  function routeLogosContainerHandler() {
    const containers = document.querySelectorAll(".route-logos");

    if (!containers) {
      return;
    }

    // containers.forEach((el) => {
    //   const routeLogos = el.querySelectorAll(".route-logo");

    //   routeLogos.forEach((el, i) => {
    //     el.style.transform = `translateX(${i * 100}%)`;
    //     el.style.left = `${i * -0.625}vw`;
    //   });

    //   el.style.width =
    //     routeLogos.length * 1.67 - (routeLogos.length - 1) * 0.625 + "vw";
    // });
    if (!containers) {
      return;
    }

    containers.forEach((el) => {
      const routeLogos = el.querySelectorAll(".route-logo");

      routeLogos.forEach((el, i) => {
        el.style.transform = `translateX(${i * 100}%)`;
        el.style.left = `${i * -12}px`;
      });

      el.style.width =
        routeLogos.length * 26 - (routeLogos.length - 1) * 12 + "px";
    });
  }
  const router =
    transactionData.router == LIFI ? transactionData.router : CHAINSPOT;

  return (
    <>
      <div className="swap-details">
        <div className="swap-details__main">
          <div className="swap-details__target">
            <img
              className="swap-details__target-icon"
              src={getTokenImg(formData.cryptoTo)}
              alt=""
            />
            <div className="swap-details__target-caption">
              <span className="swap-details__target-title">
                {(parseFloat(formData.amountTo) || 0).toFixed(4)}{" "}
                {formData.cryptoTo.symbol}
              </span>
              <div className="swap-details__target-details">
                <span className="swap-details__target-details-item">
                  {" "}
                  on{" "}
                  {capitalizeFirstLetter(
                    getNetworkNameByChainId(formData.routeTo.chainId)
                  )}
                </span>
                <span className="swap-details__target-details-item">
                  ${formData.amountToUSD}
                </span>
                <span className="swap-details__target-details-item swap-details__fee tooltip-cnt">
                  <img src="img/check-icon.svg" alt="" />${difAmountUSD}
                  <div className="tooltip-popup tooltip-popup_fee">
                    <span>Estimated network fee:</span>
                    <span>
                      {transactionData.calcFee.amountInNative.toFixed(4)}
                      {nativeToken && nativeToken.symbol} (${difAmountUSD})
                    </span>
                  </div>
                </span>
              </div>
            </div>
            <div className="swap-details__info">
              <div className="swap-details__info-item tooltip-cnt">
                <span className="swap-details__info-icon">
                  <img src="/img/time-icon.svg" alt="" />
                </span>
                <span className="swap-details__info-caption">
                  {transferTime.minute}
                </span>
                <div className="tooltip-popup">
                  <span>
                    Estimated swap execution
                    <br />
                    time in minutes
                  </span>
                </div>
              </div>
              <div className="swap-details__info-item tooltip-cnt">
                <span className="swap-details__info-icon">
                  <img src="/img/gas-icon.svg" alt="" />
                </span>
                <span className="swap-details__info-caption">
                  ${difAmountUSD}
                </span>
                <div className="tooltip-popup tooltip-popup_fee">
                  <span>Estimated network fee:</span>
                  <span>
                    {transactionData.calcFee.amountInNative.toFixed(4)}{" "}
                    {nativeToken && nativeToken.symbol} (${difAmountUSD})
                  </span>
                </div>
              </div>
              <div className="swap-details__info-item tooltip-cnt">
                <span className="swap-details__info-icon">
                  <img src="/img/steps-icon.svg" alt="" />
                </span>
                <span className="swap-details__info-caption">
                  {transactionData.hopsCount}
                </span>
                <div className="tooltip-popup">
                  <span>
                    {" "}
                    {transactionData.hopsCount}{" "}
                    {transactionData.hopsCount > 1
                      ? "transactions"
                      : "transaction"}
                  </span>
                </div>
              </div>
            </div>
            <div className="swap-details__path">
              {transactionData.router && (
                <div className="swap-details__path-item tooltip-cnt">
                  <span>Router</span>
                  <div className="estimated-token__route-logos route-logos">
                    <img
                      className="estimated-token__route-logo route-logo"
                      src={getRouteImage(router)}
                      alt=""
                    />
                  </div>
                  <div className="tooltip-popup">
                    <span>{capitalizeFirstLetter(router)}</span>
                  </div>
                </div>
              )}
              {transactionData.bridge && (
                <div className="swap-details__path-item tooltip-cnt">
                  <span>Bridges</span>
                  <div className="estimated-token__route-logos route-logos">
                    {getBridgeImageByName(transactionData.bridge) && (
                      <img
                        className="estimated-token__route-logo route-logo"
                        src={getBridgeImageByName(transactionData.bridge)}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="tooltip-popup" style={{ left: "15px" }}>
                    <span>{capitalizeFirstLetter(transactionData.bridge)}</span>
                  </div>
                </div>
              )}
              {formData.cryptoTo.symbol != formData.cryptoFrom.symbol && (
                <div className="swap-details__path-item tooltip-cnt">
                  <span>DEX</span>
                  <div className="estimated-token__route-logos route-logos">
                    <img
                      className="estimated-token__route-logo route-logo"
                      src="/images/currency-services/1inch.svg"
                      alt=""
                    />
                  </div>
                  <div className="tooltip-popup" style={{ left: "15px" }}>
                    <span>Transfer via 1inch</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
