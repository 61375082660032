import React, { useEffect, useState } from "react";
//import "./style.css";
import Layout from "./components/Layout/Layout";
// import "./assets/css/style.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  autoConnectWallet,
  networkChanged,
} from "./features/walletService/walletService";
import TransferForm from "./pages/Transfer/TransferForm";
import "./assets/scss/style.scss";

function App() {
  let dispatch = useDispatch();
  // dispatch(connectWallet())
  let isPopupOpened = useSelector(
    (state) => state.walletServiceProvider.isPopupOpened
  );

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        dispatch(networkChanged(_chainId));
        console.log(_chainId, "_chainId");
      });
      // window.ethereum.on('disconnect', () => {
      //   window.location.reload();
      // })
    }
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    dispatch(autoConnectWallet());
    // }, 100);
  }, [dispatch]);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <div className="container">
        <label>
          <input
            type="checkbox"
            onChange={() => setIsChecked((prev) => !prev)}
          />
          <span>Horizontal</span>
        </label>
        <div className={`widget ${isChecked ? 'hor' : ''} `}>
          <TransferForm />
        </div>
      </div>
    </>
  );
}
export default App;
