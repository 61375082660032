import React from "react";
import {
  connectWallet,
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  showAgreementDialog,
} from "../../../../features/walletService/walletService";
import { useDispatch } from "react-redux";
import { isMobileDevice } from "../../../../utils/BrowserUtil";
import { MetamaskWebProvider } from "../../../../services/metamask/MetamaskWebProvider";

function ChooseWalletItem({ walletService, onSelect, isTermAgreed }) {
  const dispatch = useDispatch();

  const isMobile = isMobileDevice();

  if (isMobile) {
    if (
      walletService.key == PROVIDER_METAMASK &&
      !MetamaskWebProvider.ethereum
    ) {
      const metamaskAppDeepLink =
        "https://metamask.app.link/dapp/" + window.location.host;
      return (
        walletService.key == PROVIDER_METAMASK && (
          <a href={metamaskAppDeepLink}>
            <button
              className="choose-wallet-popup__wallet-item"
              // onClick={() => window.open(metamaskAppDeepLink,'_self')}
            >
              <div className="choose-wallet-popup__wallet-item-title">
                <img src={walletService.img} alt={walletService.title} />
                <span>{walletService.title}</span>
              </div>
            </button>
          </a>
        )
      );
    }
    if (walletService.key == PROVIDER_EVERWALLET) {
      return <></>;
    }
  }

  return (
    <button
      key={walletService.key}
      className="connect-wallet-popup__wallet"
      onClick={() => onSelect(walletService.key)}
    >
      {walletService.title}
      <img src={walletService.img} alt={walletService.title} />
    </button>
  );
}
export default ChooseWalletItem;
