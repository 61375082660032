import SwapDetail from "../../components/Swap/SwapDetail";
import SwapDetailLoading from "./SwapDetailLoading";
import SwapNoRouteFound from "../../components/Swap/SwapNoRouteFound";
import {
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  STEP_READY_PREVIEW,
  STEP_SWITCH_NETWORK,
} from "../../constants/TransferConstants";
import SwapDetailNoRoute from "./SwapDetailNoRoute";

export default function SwapInfoBlock({
  formData,
  transactionData,
  transactionStep,
}) {
  if(transactionStep == STEP_LOAD_QUOTA){
    return <SwapDetailLoading />
  }
  if(transactionStep == STEP_FORM_NO_ROUTE_FOUND){
    return <SwapNoRouteFound />
  }
  if([STEP_READY_PREVIEW].includes(transactionStep) && transactionData.transactionId){
    return <SwapDetail formData={formData} transactionData={transactionData} />
  }
  return (
    <>
      <SwapDetailNoRoute/>
    </>
  );
}
