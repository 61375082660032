import { constants } from "ethers";

export const isZeroAddress = (address) => {
  if (
    address === constants.AddressZero ||
    address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" ||
    address === null
  ) {
    return true;
  }
  return false;
};

export const isNativeTokenAddress = (address) => {
  if (
    address === constants.AddressZero ||
    address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee" ||
    // CELO native token
    address === "0x471ece3750da237f93b8e339c536989b8978a438"
  ) {
    return true;
  }
  return false;
};

export const cutNumber = (num, fraction = 4) => {
  const re = new RegExp("/^-?d+(?:.d{0,4})?/"); //^-?\d+(?:\.\d{0,4})?
  return num.toString().match(re)[0];
};

export const truncateDecimals = (num, digits = 4) => {
  var numS = num.toString(),
    decPos = numS.indexOf("."),
    substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
    trimmedResult = numS.substr(0, substrLength),
    finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

  return parseFloat(finalResult);
};
