import { useEffect, useMemo } from "react";
import { CHAINSPOT, LIFI } from "../../constants/ServiceConstants";
import {
  BTN_KEY_APPROVE,
  BTN_KEY_RETURN_SWAP_FORM,
} from "../../constants/SwapButtonConstants";
import { STEP_READY_APPROVE } from "../../constants/TransferConstants";
import { isZeroAddress } from "../../features/walletService/utils";
import {
  capitalizeFirstLetter,
  getBridgeImageByName,
  getNetworkImageByChainId,
  getNetworkNameByChainId,
  getTokenImg,
} from "../../utils/NetworkList";
import { getRouteImage, getServiceImage } from "../../utils/TransferApiUtil";

export default function ConfirmationBlock({
  formData,
  transactionData,
  transactionStep,
  btnHandler,
}) {
  if (transactionStep != STEP_READY_APPROVE) {
    return;
  }

  const difAmountUSD = transactionData.calcFee?.amountInUsd.toFixed(2);
  const nativeToken = formData.routeFrom.cryptos.find((v) =>
    isZeroAddress(v.contractAddress)
  );

  const router =
    transactionData.router == LIFI ? transactionData.router : CHAINSPOT;

  return (
    <>
      <div className="widget-popup confirmation-popup">
        <div className="widget-popup__header confirmation-popup__header">
          <button
            className="widget-popup__back-btn"
            onClick={() => btnHandler(BTN_KEY_RETURN_SWAP_FORM)}
          >
            Back
          </button>
          <h3 className="widget-popup__title">Confirmation</h3>
        </div>

        <div  className="confirmation__cnt">
          <div  className="confirmation">
            <div  className="confirmation__from">
              <div  className="confirmation-token">
                <div  className="confirmation-token__icons">
                  <img
                     className="confirmation-token__icon confirmation-token__icon_token"
                    src={getTokenImg(formData.cryptoFrom)}
                    alt=""
                  />
                  <img
                     className="confirmation-token__icon confirmation-token__icon_network"
                    src={getNetworkImageByChainId(formData.routeFrom.chainId)}
                    alt=""
                  />
                </div>
                <div  className="confirmation-token__value">
                  <span  className="confirmation-token__token">
                    {formData.cryptoFrom.symbol}
                  </span>
                  <span  className="confirmation-token__network">
                    on {formData.routeFrom.name}
                  </span>
                  <span  className="confirmation-token__balance">
                    ${formData.amountFromUSD}
                  </span>
                </div>
              </div>
            </div>
            <div  className="confirmation__to">
              <div  className="confirmation-token">
                <div  className="confirmation-token__icons">
                  <img
                     className="confirmation-token__icon confirmation-token__icon_token"
                    src={getTokenImg(formData.cryptoTo)}
                    alt=""
                  />
                  <img
                     className="confirmation-token__icon confirmation-token__icon_network"
                    src={getNetworkImageByChainId(formData.routeTo.chainId)}
                    alt=""
                  />
                </div>
                <div  className="confirmation-token__value">
                  <span  className="confirmation-token__token">
                    {formData.cryptoTo.symbol}
                  </span>
                  <span  className="confirmation-token__network">
                    on {formData.routeTo.name}
                  </span>
                  <span  className="confirmation-token__balance">
                    ${formData.amountFromUSD}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div  className="confirmation-details">
            <div  className="confirmation-details__item">
              <div  className="confirmation-details__name">
                <img src="/img/fee-icon.svg" alt="" />
                <span>Estimated gas fee</span>
                <button  className="info-button"></button>
              </div>
              <span  className="confirmation-details__value">~{difAmountUSD}</span>
            </div>
            {formData.cryptoTo.symbol != formData.cryptoFrom.symbol && (
              <div  className="confirmation-details__item">
                <div  className="confirmation-details__name">
                  <img src="/images/currency-services/1inch.svg" alt="" />
                  <span>DEX</span>
                </div>
                <span  className="confirmation-details__value">1Inch</span>
              </div>
            )}
            {transactionData.bridge && (
              <div  className="confirmation-details__item">
                <div  className="confirmation-details__name">
                  {getBridgeImageByName(transactionData.bridge) && (
                    <img
                      src={getBridgeImageByName(transactionData.bridge)}
                      alt=""
                    />
                  )}
                  <span>Bridge via</span>
                </div>
                <span  className="confirmation-details__value">
                  {capitalizeFirstLetter(transactionData.bridge)}
                </span>
              </div>
            )}
            {transactionData.router && (
              <div  className="confirmation-details__item">
                <div  className="confirmation-details__name">
                  <img src={getRouteImage(router)} alt="" />
                  <span>Router</span>
                </div>
                <span  className="confirmation-details__value">
                  {capitalizeFirstLetter(router)}
                </span>
              </div>
            )}
          </div>
          <button
             className="confirmation-popup__submit widget-form__submit"
            onClick={() => btnHandler(BTN_KEY_APPROVE)}
          >
            Start swap
          </button>
        </div>
      </div>
    </>
  );
}
