import { ONE_INCH, SYMBIOISES } from "../constants/ServiceConstants";
import {
  PROVIDER_EVERWALLET,
  PROVIDER_METAMASK,
  PROVIDER_TRON,
} from "../features/walletService/walletService";

export const networkListDat = [
  {
    id: "3de3bba1-222b-4da9-b1bf-94d6eb358f83",
    name: "Ethereum",
    symbol: "ETH",
    chainId: "1",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    img: "ethereum-24x24.svg",
    currency: "ETH",
    txScanUrl: "https://etherscan.io/tx/",
  },
  {
    id: "14d7a5b1-3c87-43dd-94e5-1b26ac5918bb",
    name: "Polygon",
    symbol: "POL",
    chainId: "137",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    img: "polygon-24x24.svg",
    currency: "MATIC",
    txScanUrl: "https://polygonscan.com/tx/",
  },
  {
    id: "e130ce89-1d4d-4951-beaf-465ec43cf399",
    name: "BSC",
    symbol: "BSC",
    chainId: "56",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: [
      "https://bsc-dataseed.binance.org/",
      "https://bsc-dataseed1.defibit.io/",
      "https://bsc-dataseed1.ninicoin.io/",
    ],
    img: "bsc-24x24.svg",
    currency: "BNB",
    txScanUrl: "https://bscscan.com/tx/",
  },
  {
    id: "b65ad594-15c0-48fd-b9b9-5ddb8a994c5f",
    name: "Gnosis",
    symbol: "DAI",
    chainId: "100",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: [
      "https://rpc.gnosischain.com/",
      "https://rpc.xdaichain.com/",
      "https://dai.poa.network/",
    ],
    img: "Gnosis-24x24-1.svg",
    currency: "xDAI",
    txScanUrl: "https://gnosisscan.io/tx/",
  },
  {
    id: "4b5a3b18-5a82-4397-9b82-2e8abc0c2806",
    name: "FUSE",
    symbol: "FUS",
    chainId: "122",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://rpc.fuse.io"],
    img: "fuse-24x24.svg",
    currency: "FUSE",
    txScanUrl: "https://explorer.fuse.io/tx/",
  },
  {
    id: "247aa6fa-524d-4d69-83bf-8576fc9124aa",
    name: "Cronos",
    symbol: "CRO",
    chainId: "25",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://evm-cronos.crypto.org"],
    img: "cronos-24x24.svg",
    currency: "CRO",
    txScanUrl: "https://cronoscan.com/tx/",
  },
  {
    id: "6a398853-7fc4-49b4-9615-7f4558577164",
    name: "Evmos",
    symbol: "EVM",
    chainId: "9001",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://eth.bd.evmos.org:8545"],
    img: "evmos-24x24.svg",
    currency: "EVMOS",
    txScanUrl: "https://escan.live/tx/",
  },
  {
    id: "f7537fef-447e-4899-9b79-f888b61c7423",
    name: "Fantom",
    symbol: "FTM",
    chainId: "250",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://rpc.ftm.tools/", "https://rpcapi.fantom.network"],
    img: "fantom-24x24.svg",
    currency: "FTM",
    txScanUrl: "https://escan.live/tx/",
  },
  {
    id: "012115c8-6614-4824-a209-50f81d5a3c76",
    name: "OKXChain",
    symbol: "OKT",
    chainId: "66",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://exchainrpc.okex.org"],
    img: "okex-24x24.svg",
    currency: "OKT",
    txScanUrl: "https://ftmscan.com/tx/",
  },
  {
    id: "4522bb53-4c78-42b2-877b-10ef910cc3f7",
    name: "Avalanche",
    symbol: "AVA",
    chainId: "43114",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    img: "avalanche-avax-logo 1.svg",
    currency: "AVAX",
    txScanUrl: "https://snowtrace.io/tx/",
  },
  {
    id: "5de15270-b6ed-4d58-8b90-ab462b079c16",
    name: "Arbitrum One",
    symbol: "ARB",
    chainId: "42161",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    img: "Arbitrum One-24x24.svg",
    currency: "ETH",
    txScanUrl: "https://arbiscan.io/tx/",
  },
  {
    id: "74f94880-6a06-4624-80bb-119198d8330a",
    name: "Optimism",
    symbol: "OPT",
    chainId: "10",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://mainnet.optimism.io/"],
    img: "Optimism-24x24.svg",
    currency: "ETH",
    txScanUrl: "https://optimistic.etherscan.io/tx/",
  },
  {
    id: "5a8af21d-5444-42e7-b108-60a58a486c60",
    name: "Harmony",
    symbol: "ONE",
    chainId: "1666600000",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://api.harmony.one"],
    img: "harmony-24x24.svg",
    currency: "ONE",
    txScanUrl: "https://explorer.harmony.one/tx/",
  },
  {
    id: "8e3d7cda-200b-442f-934c-a922b0e7d1f2",
    name: "Moonriver",
    symbol: "MOR",
    chainId: "1285",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://rpc.api.moonriver.moonbeam.network"],
    img: "moonriver-24x24.svg",
    currency: "MOVR",
    txScanUrl: "https://moonriver.moonscan.io/tx/",
  },
  {
    id: "821a3bb2-73db-46a1-8425-f1478c059bea",
    name: "Moonbeam",
    symbol: "MOO",
    chainId: "1284",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://rpc.api.moonbeam.network"],
    img: "Moonbeam-24x24.svg",
    currency: "GLMR",
    txScanUrl: "https://moonbeam.moonscan.io/tx/",
  },
  {
    id: "84b7be19-1c65-4e99-8850-c9de85bbaa54",
    name: "CELO",
    symbol: "CEL",
    chainId: "42220",
    address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    rpcUrls: ["https://forno.celo.org"],
    img: "Celo-24x24.svg",
    currency: "CELO",
    txScanUrl: "https://celoscan.io/tx/",
  },
  {
    id: "e330c851-432a-4db5-8463-eb4c467ea74e",
    name: "Aurora",
    symbol: "AOA",
    chainId: "1313161554",
    address: null,
    rpcUrls: ["https://mainnet.aurora.dev"],
    img: "aurora-24x24.svg",
    currency: "ETH",
    txScanUrl: "https://aurorascan.dev/tx/",
  },
  {
    id: "c213f7c3-b5db-469a-af54-2842048b8439",
    name: "Telos",
    symbol: "TLOS",
    chainId: "40",
    address: null,
    rpcUrls: ["https://mainnet.telos.net/evm"],
    img: "telos-24x24.svg",
    currency: "TLOS",
    txScanUrl: "https://www.teloscan.io/tx/",
  },
  {
    id: "7a622444-7f66-476d-912f-6cf06fb27b48",
    name: "Bobabeam",
    symbol: "BOBA",
    chainId: "1294",
    address: null,
    rpcUrls: ["https://bobabeam.boba.network"],
    img: "boba-24x24.svg",
    currency: "ETH",
    txScanUrl: "https://blockexplorer.bobabeam.boba.network/tx/",
  },
];

export function getNetworkNameByChainId(networkChainId,name) {
  return (
    (networkChainId &&
      networkListDat.find((v) => v.chainId == networkChainId)?.name) ||
      name
  );
}

export function getTxScanUrl(networkChainId, tx) {
  let network = networkListDat.find((v) => v.chainId == networkChainId);
  return network ? network.txScanUrl + tx : null;
}

const networkImageFolder = "/images/network-logos/";

export function getNetworkImage(networkId) {
  const item = networkListDat.find((v) => v.key == networkId);
  return item ? networkImageFolder + item.img : "";
}

export function getNetworkImageByChainId(networkChainId) {
  let item = networkListDat.find((v) => v.chainId == networkChainId);
  return networkImageFolder + (item ?  item.img : "unknown-logo.png");
}

export function getNetworkImageByName(name) {
  let item = networkListDat.find((v) => v.name == name);
  return item ? networkImageFolder + item.img : "";
}

const Bridges = [
  {
    name: "Across",
    img: "Across.svg",
  },
  {
    name: "Arbitrum Bridge",
    img: "Arbitrum Bridge.svg",
  },
  {
    name: "Avalanche Bridge",
    img: "Avalanche Bridge.svg",
  },
  {
    name: "Biconomy",
    img: "Biconomy.svg",
  },
  {
    name: "cBridge",
    img: "cBridge.svg",
  },
  {
    name: "Connext",
    img: "Connext.svg",
  },
  {
    name: "Hop",
    img: "Hop.svg",
  },
  {
    name: "Moonbeam",
    img: "Moonbeam-24x24.svg",
  },
  {
    name: "Multichain",
    img: "Multichain.svg",
  },
  {
    name: "Optimism Gateway",
    img: "Optimism Gateway.svg",
  },
  {
    name: "Polygon",
    img: "Polygon.svg",
  },
  {
    name: "Stargate",
    img: "Stargate.svg",
  },
  {
    name: SYMBIOISES,
    img: "Symbiosis.svg",
  },
  {
    name: ONE_INCH,
    img: "1inch.svg",
  },
];

export function getBridgeImageByName(name) {
  let item = Bridges.find(
    (v) => v.name.toLowerCase() == (name || "").toLowerCase()
  );
  return item ? "/images/bridge-logos/" + item.img : "";
}

export const WalletList = [
  {
    title: "Metamask",
    key: PROVIDER_METAMASK,
    img: "./images/metamask-logo.png",
    imgLarge: "./images/metamask-logo-large.png",
  },
  {
    title: "Ever Wallet",
    key: PROVIDER_EVERWALLET,
    img: "./images/everwallet-logo.svg",
    imgLarge: "",
  },
  {
    title: "TronLink",
    key: PROVIDER_TRON,
    img: "https://tron.network/static/images/header/logo-red.svg",
    imgLarge: "https://tron.network/static/images/header/logo-red.svg",
  },
];

export function getWalletServiceImg(key) {
  let item = WalletList.find((v) => v.key == key);
  return item ? item.imgLarge : "";
}

export function getTokenImg(token) {
  return token && token.logoURI
    ? token.logoURI
    : token.symbol == "USDT"
    ? "/images/tokens-logos/token.png"
    : "/images/tokens-list-logos/omg.png";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
