import WalletConnectButton from "../Layout/Header/WalletConnectButton/WalletConnectButton";

export default function SwapTitle() {
  return (
    <div className="widget__header">
  		<img  className="widget__logo" src="img/revain-logo.png" width="89" height="24" alt=""/>
      {/* <img src="img/revain-logo.svg" width="66" height="17" alt="" /> */}
      <WalletConnectButton/>
    </div>
  );
}
