import {
  BTN_KEY_APPROVE_RETRY,
  BTN_KEY_PREVIEW,
  BTN_KEY_RETURN_SWAP_FORM,
  BTN_KEY_TRANSFER,
  BTN_START_FROM_ROUTE,
} from "../../constants/SwapButtonConstants";
import {
  TYPE_REFUSED,
  TYPE_TRANSFER,
} from "../../constants/TransactionStepConstants";
import {
  STEP_APPROVE_APPROVED,
  STEP_APPROVE_FINISHED,
  STEP_APPROVE_REJECTED,
  STEP_APPROVE_STARTED,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_REJECTED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import {
  getNetworkImageByChainId,
  getTokenImg,
  getTxScanUrl,
} from "../../utils/NetworkList";

export default function TransactionStepAlert({
  formData,
  closePopup,
  process,
  step,
}) {
  // console.log(process?.state,'process?.state TransactionStepAlert')
  if (!process) {
    return;
  }
  // console.log(process, "in process");
  return (
    <>
      {[STEP_APPROVE_STARTED, STEP_APPROVE_APPROVED].includes(step) && (
        <div className="approve-token-popup service-popup">
          <h2 className="service-popup__title">Approve token</h2>

          <div className="service-popup__icon service-popup__icon_loader">
            <span className="loader-violet"></span>
          </div>

          <p className="service-popup__text">
            Please approve {formData.cryptoFrom.symbol} before you continue the
            cross-chain transfer
          </p>
        </div>
      )}
      {step === STEP_APPROVE_REJECTED && (
        <div className="decline-popup service-popup">
          <h2 className="service-popup__title">Declined</h2>

          <div className="service-popup__icon service-popup__icon_decline">
            <img src="img/decline-icon.svg" alt="" />
          </div>

          <p className="service-popup__text service-popup__text_gray">
            You have declined permission to use the token. Please try again and
            give permission to spend the token to continue the transfer.
          </p>

          <div
            className="btn"
            onClick={() => closePopup(BTN_KEY_RETURN_SWAP_FORM)}
          >
            Back to swap
          </div>
        </div>
      )}
      {step === STEP_APPROVE_FINISHED && (
        <div className="approved-popup service-popup">
          <h2 className="service-popup__title">Approved</h2>
          <button className="service-popup__close"></button>

          <div className="service-popup__icon service-popup__icon_approved">
            <img src="/img/approved-icon.svg" alt="" />
          </div>

          <p className="service-popup__text service-popup__text_gray">
            Token {formData.cryptoFrom.symbol} was successfully approved. Now
            you can proceed to transfer
          </p>

          <button className="btn" onClick={() => closePopup(BTN_KEY_TRANSFER)}>
            Proceed swap
          </button>
        </div>
      )}
      {step === STEP_TRANSFER_START && (
        <div className="sign-transaction-popup service-popup">
          <h2 className="service-popup__title">Sign the transaction</h2>

          <div className="service-popup__icon service-popup__icon_loader">
            <span className="loader-violet"></span>
          </div>

          <p className="service-popup__text service-popup__text_gray">
            Please sign the transaction
          </p>

          <span className="service-popup__text service-popup__text_time">
            <img src="img/clock-icon.svg" alt="" />
            Transfer time few minutes
          </span>
        </div>
      )}
      {step === STEP_TRANSFER_REJECTED && (
        <div className="decline-popup service-popup">
          <h2 className="service-popup__title">Transfer rejected</h2>

          <div className="service-popup__icon service-popup__icon_decline">
            <img src="img/decline-icon.svg" alt="" />
          </div>

          <p className="service-popup__text service-popup__text_gray">
            Something went wrong.
            <br />
            Please try again.
          </p>

          <div
            className="btn"
            style={{ marginTop: "auto" }}
            onClick={() => closePopup(BTN_KEY_TRANSFER)}
          >
            Try one more time
          </div>
        </div>
      )}
      {step === STEP_TRANSFER_APPROVED && (
        <div className="proccess-popup service-popup">
          <div className="proccess-popup__token-info">
            <div className="proccess-popup__token-icon">
              <img
                className="proccess-popup__token"
                src={getTokenImg(formData.cryptoFrom)}
                alt=""
              />
              <img
                className="proccess-popup__network"
                src={getNetworkImageByChainId(formData.routeFrom.chainId)}
                alt=""
              />
            </div>
            <span>
              {formData.cryptoFrom.symbol} on {formData.routeFrom.name}
            </span>
            <span>${formData.amountFromUSD}</span>
          </div>

          <span className="proccess-popup__sum">{formData.amountTo}</span>

          <span className="service-popup__text service-popup__text_time">
            <img src="img/clock-icon.svg" alt="" />
            Transfer time few minutes
          </span>

          <span className="service-popup__text service-popup__text_gray">
            You will receive your assets
            <br />
            in your wallet on {formData.routeTo.name}
          </span>

          <div  className="service-popup__cta">
            <div  className="proccess-popup__pending">
              <span  className="loader-violet"></span>
              <span>Transaction pending</span>
            </div>

            <button
               className="btn"
              onClick={() => closePopup(BTN_START_FROM_ROUTE)}
            >
              Make a new swap
            </button>
          </div>
        </div>
      )}
      {step === STEP_TRANSFER_FINISHED && (
        <div className="proccess-popup service-popup">
          <div className="proccess-popup__token-info">
            <div className="proccess-popup__token-icon">
              <img
                className="proccess-popup__token"
                src={getTokenImg(formData.cryptoFrom)}
                alt=""
              />
              <img
                className="proccess-popup__network"
                src={getNetworkImageByChainId(formData.routeFrom.chainId)}
                alt=""
              />
            </div>
            <span>
              {formData.cryptoFrom.symbol} on {formData.routeFrom.name}
            </span>
            <span>${formData.amountFromUSD}</span>
          </div>

          <span className="proccess-popup__sum">{formData.amountTo}</span>
          <span  className="proccess-popup__success">
            <img src="/img/approved-nobg-icon.svg" alt="" />
            Success!
          </span>

          <span className="service-popup__text service-popup__text_gray">
            You will receive your assets
            <br />
            in your wallet on {formData.routeTo.name}
          </span>

          <div  className="service-popup__cta">
            <a
              className="external-link"
              href={getTxScanUrl(
                formData.routeFrom.chainId,
                process.transfetTx
              )}
              target={"_blank"}
            >
              View details
            </a>

            <button
               className="btn"
              onClick={() => closePopup(BTN_START_FROM_ROUTE)}
            >
              Make a new swap
            </button>
          </div>
        </div>
      )}
    </>
  );
}
