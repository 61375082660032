import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { Address } from "everscale-inpage-provider";
import { EverWalletWebProvider } from "../../services/everWallet/everWalletWebProvider";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { TronLinkWebProvider } from "../../services/tronLink/TronLinkWebProvider";
import { localStorageObj } from "../localstorage";

export const PROVIDER_METAMASK = "metamask";
export const PROVIDER_EVERWALLET = "everWallet";
export const PROVIDER_TRON = "tronWallet";

export const getWalletAmount = async (walletInfo, formData) => {
  let balance = 0;
  try {
    switch (walletInfo.providerType) {
      case PROVIDER_METAMASK:
        balance = await MetamaskWebProvider.getBalance(
          formData.cryptoFrom,
          walletInfo.accountAddress
        );
        break;
      case PROVIDER_EVERWALLET:
        balance = await EverWalletWebProvider.getBalance(
          new Address(walletInfo.accountAddress)
        );
        break;
    }
  } catch (error) {
    
  }
  console.log(balance,'balance')
  return balance;
};

export const approveWalletTransaction = async (
  amount,
  walletInfo,
  formData,
  transactionData,
) => {
  let proccessResponce = {
    isApproved:false,
    networkResp:null
  }
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      proccessResponce = await MetamaskWebProvider.approve(
        amount,
        formData.cryptoFrom.contractAddress,
        walletInfo.accountAddress,
        transactionData.approvalAddress,
      );
      break;
    case PROVIDER_EVERWALLET:
      break;
  }
  return proccessResponce;
};


export const sendWalletTransaction = async (
  walletInfo,
  transactionData,
  dispatch
) => {
  let transData = null;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      transData = await MetamaskWebProvider.sendTransaction(
        transactionData,
        dispatch
      );
      break;
    case PROVIDER_EVERWALLET:
      break;
  }
  return transData;
};

export const autoConnectWallet = createAsyncThunk(
  "autoconnect/wallet",
  async (__, { dispatch }) => {
    let providerType = localStorageObj.get("providerType");
    let walletInfo = { ...initialState.walletInfo };
    if (providerType == null) {
      return walletInfo;
    }

    switch (providerType) {
      case PROVIDER_METAMASK:
        walletInfo = await MetamaskWebProvider.autoConnect(dispatch, walletInfo);
        break;
      case PROVIDER_EVERWALLET:
        walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRON:
        walletInfo = await TronLinkWebProvider.connect(dispatch, walletInfo);
        break;

      default:
        break;
    }

    walletInfo.providerType = providerType;
    if(walletInfo){
      dispatch(setWallet(walletInfo));
    }
  }
);

export const connectWallet = createAsyncThunk(
  "connect/wallet",
  async (providerType = null, { dispatch, getState }) => {
    dispatch(setConnectingWallet(true));
    if (!providerType) {
      providerType = localStorageObj.get("providerType");
      let isConnected = true;
      if (providerType) {
        switch (providerType) {
          case PROVIDER_METAMASK:
            isConnected = await MetamaskWebProvider.isConnected();
            break;
          case PROVIDER_EVERWALLET:
            isConnected = await EverWalletWebProvider.isConnected();
            break;
          case PROVIDER_TRON:
            isConnected = await TronLinkWebProvider.isConnected();
            break;

          default:
            break;
        }
      }
      console.log(providerType, isConnected, "providerType in storage");
      if (!providerType || !isConnected) {
        localStorageObj.remove("providerType");
        return null;
      }
    }
    const state = getState();
    let walletInfo = {
      accountAddress: null,
      balance: null,
      isConnected: false,
      providerType: providerType,
    };
    switch (providerType) {
      case PROVIDER_METAMASK:
        walletInfo = await MetamaskWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_EVERWALLET:
        walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
        break;
      case PROVIDER_TRON:
        walletInfo = await TronLinkWebProvider.connect(dispatch, walletInfo);
        break;

      default:
        break;
    }
    // console.log(walletInfo, providerType, "providerType");
    if (walletInfo && walletInfo.isConnected)
      localStorageObj.set("providerType", providerType);
    dispatch(setConnectingWallet(false));
    return walletInfo;
  }
);

export const getTokenBalance = async (provider,accountAddress, routeFrom,cryptoFrom,curChainId) => {
  let balance = 0;
  switch (provider) {
    case PROVIDER_METAMASK:
      balance = await MetamaskWebProvider.getTokenBalanceByContractAddress(
        routeFrom,
        cryptoFrom,
        accountAddress,
        curChainId
      );
      break;
  }
  return balance;
};


const initialState = {
  walletInfo: {
    accountAddress: null,
    balance: null,
    isConnected: false,
    providerType: null,
    networkChainId: null,
  },
  showWalletTypePopup: false,
  showAgreementPopup: false,
  connectingWallet: false,
  showWalletInfoPopup: false,
  showWalletDisconnectPopup: false,
  status: "idle",
  error: null,
  preselectedWalletKey: null,
  isPopupOpened: false,
};

export const walletServiceProvider = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    accountChanged: (state, accounts) => {
      if (accounts.length > 0) state.walletInfo.accountAddress = accounts[0];
    },
    networkChanged: (state, action) => {
      state.walletInfo.networkChainId = parseInt(action.payload);
    },
    walletTypeDialogToggle: (state) => {
      state.showWalletTypePopup = !state.showWalletTypePopup;
      state.isPopupOpened = isOpenedAnyPopup(state);
    },
    showAgreementDialog: (state, action) => {
      state.showWalletTypePopup = false;
      state.showAgreementPopup = true;
      state.preselectedWalletKey = action.payload;
    },
    agreementDialogToggle: (state) => {
      state.showAgreementPopup = !state.showAgreementPopup;
    },
    providerSelected: (state, provider) => {
      state.providerType = provider;
    },
    walletDisconnectDialogToggle: (state) => {
      state.showWalletDisconnectPopup = !state.showWalletDisconnectPopup;
    },
    walletInfoDialogToggle: (state) => {
      state.showWalletInfoPopup = !state.showWalletInfoPopup;
      state.isPopupOpened = isOpenedAnyPopup(state);
    },
    disconnect: (state) => {
      localStorageObj.remove("providerType");
      state.walletInfo = initialState;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setConnectingWallet: (state, action) => {
      state.connectingWallet = action.payload;
    },
    setWallet: (state, action) => {
      state.walletInfo = { ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectWallet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload) state.walletInfo = action.payload;
        state.showWalletTypePopup = false;
        state.showAgreementPopup = false;
        state.isPopupOpened = isOpenedAnyPopup(state);
      })
      .addCase(connectWallet.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.showWalletTypePopup = false;
        state.isPopupOpened = isOpenedAnyPopup(state);
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  accountChanged,
  walletTypeDialogToggle,
  walletInfoDialogToggle,
  walletDisconnectDialogToggle,
  providerSelected,
  disconnect,
  setError,
  networkChanged,
  setWallet,
  setConnectingWallet,
  agreementDialogToggle,
  showAgreementDialog,
} = walletServiceProvider.actions;

export default walletServiceProvider.reducer;

function isOpenedAnyPopup(state){
  console.log(state.showWalletTypePopup,state.showWalletInfoPopup,'state.showWalletInfoPopup')
  return state.showWalletTypePopup || state.showWalletInfoPopup;
}