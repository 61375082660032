import { utils } from 'ethers'
import { ProviderRpcClient } from 'everscale-inpage-provider'
import { ALERT_WARNING } from '../../constants/AlertTypes';
import { showNotifaction } from '../../features/dialogs/notificationPopupSlice';
import { setError } from '../../features/walletService/walletService'

const ERROR_EVER_NOT_INSTALLED = 'Ever Extension is not installed';
const INSUFFICEINT_PERMISSION = 'Insufficient permissions';
export const EverWalletWebProvider = {
  ever: function () {
    return new ProviderRpcClient()
  },
  isConnected: async function () {
    const ever = this.ever()
    let isConnected = false
    if (await ever.hasProvider()) {
      try {
        await ever.ensureInitialized()
        isConnected = true
      } catch (error) {}
    }
    return isConnected
  },
  connect: async function (dispatch, walletInfo) {
    const ever = this.ever()
    if (!(await ever.hasProvider())) {
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: ERROR_EVER_NOT_INSTALLED}));
      return
    }

    const { accountInteraction } = await ever.requestPermissions({
      permissions: ['basic', 'accountInteraction'],
    })

    if (accountInteraction == null) {
      dispatch(showNotifaction({ alertType: ALERT_WARNING, caption: INSUFFICEINT_PERMISSION}));
      return
    }
    const selectedAddress = accountInteraction.address

    walletInfo.accountAddress = selectedAddress.toString()
    walletInfo.isConnected = true
    return walletInfo
  },
  getBalance: async function (address) {
    console.log(address, 'balance')
    const ever = this.ever()
    const { state } = await ever.getFullContractState({
      address: address,
    })
    return state ? parseFloat(utils.formatUnits(state.balance)) : 0
    // return state ? state.balance : 0
  },
}
