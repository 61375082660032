import { configureStore } from '@reduxjs/toolkit'
import walletServiceProvider from '../features/walletService/walletService'
import switchNetworkSlice from '../features/dialogs/switchNetworkSlice'
import notificationPopupSlice from '../features/dialogs/notificationPopupSlice'
import chooseTokenSlice from '../features/dialogs/chooseTokenSlice'

export default configureStore({
  reducer: {
    walletServiceProvider: walletServiceProvider,
    switchNetworkPopup:switchNetworkSlice,
    notificationPopupManager:notificationPopupSlice,
    chooseTokenManager:chooseTokenSlice
  },
})
