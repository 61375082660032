import { createSlice } from "@reduxjs/toolkit";

const initialStateData = {
  show: false,
  routes: [],
  directType: null,
  routeFrom: null,
  cryptoFrom: null,
  curRoute: null,
  selectedItem: null,
};
export const chooseTokenSlice = createSlice({
  name: "ChooseToken",
  initialState: initialStateData,
  reducers: {
    showChooseToken: (state, action) => {
      state.show = true;
      state.routes = action.payload.routes;
      state.directType = action.payload.directType;
      state.routeFrom = action.payload.routeFrom;
      state.cryptoFrom = action.payload.cryptoFrom;
      state.curRoute = action.payload.curRoute;
    },
    closeChooseToken: (state, action) => {
      state.show = false;
      state.routes = initialStateData.routes;
      state.directType = initialStateData.directType;
      state.routeFrom = initialStateData.routeFrom;
      state.cryptoFrom = initialStateData.cryptoFrom;
      state.curRoute = initialStateData.curRoute;
      state.selectedItem = action.payload || null;
    },
    clearChooseToken: (state) => {
      state.selectedItem = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showChooseToken, closeChooseToken, clearChooseToken } =
  chooseTokenSlice.actions;

export default chooseTokenSlice.reducer;
