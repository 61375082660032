import { useSelector } from "react-redux";
import SwapContentLoading from "./SwapContentLoading";
import SwapForm from "./SwapForm";
import SwapInfoBlock from "./SwapInfoBlock";
import SwapButton from "../../components/Swap/SwapButton";
import CircleProgressBar from "../CircleProgressBar/CircleProgressBar";

export default function SwapFormBock({
  isLoading,
  handleSubmit,
  formData,
  replaceNetwork,
  fieldChange,
  tokenBalance,
  openTokenSelector,
  setMaxAmount,
  tokenOutBalance,
  transactionData,
  transactionStep,
  btnHandler,
  timeLeft,
  startLoadingQuota,
}) {
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  return (
    <>
      {isLoading && <SwapContentLoading />}
      {false && timeLeft > 0 && (
        <div className="cirleWrap" onClick={startLoadingQuota}>
          <CircleProgressBar
            size={30}
            trackWidth={2}
            indicatorWidth={2}
            progress={timeLeft * 1.6}
          />
        </div>
      )}
      <form className="widget-form" onSubmit={handleSubmit}>
        <fieldset className="widget-form__main">
          <SwapForm
            formData={formData}
            replaceNetwork={replaceNetwork}
            fieldChange={fieldChange}
            tokenBalance={tokenBalance}
            openTokenSelector={openTokenSelector}
            setMaxAmount={setMaxAmount}
            tokenOutBalance={tokenOutBalance}
          />
            <SwapInfoBlock
              formData={formData}
              transactionData={transactionData}
              transactionStep={transactionStep}
              tokenBalance={tokenBalance}
            />
          <SwapButton
            transactionStep={transactionStep}
            formData={formData}
            btnHandler={btnHandler}
          />
        </fieldset>
      </form>
    </>
  );
}
