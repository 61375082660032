import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveWalletTransaction,
  getTokenBalance,
  sendWalletTransaction,
  setError,
} from "../../features/walletService/walletService";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { BigNumber, ethers, utils } from "ethers";
import {
  formatAmountToUint,
  getCryptoInUSD,
  getDataByUrl,
  getStatus,
  isAmountLess,
  numberToHex,
  setApprove,
  setHash,
  transferStart,
} from "../../utils/TransferApiUtil";
import axios from "axios";
import { isZeroAddress } from "../../features/walletService/utils";
import {
  amountErrorText,
  BSC_CHAIN_ID,
  DEFAULT_TOKEN_SYMBOL,
  DEFAULT_TOKEN_SYMBOL2,
  ETH_CHAIN_ID,
  GAS_AMOUNT_USD,
  GAS_AMOUNT_USD_ETHER,
  MAX_ROUTE_REFRESH_TIME,
  PROCESS_FIND_ROUTE,
  PROCESS_SWAP_ROUTE,
  STEP_APPROVE_APPROVED,
  STEP_APPROVE_FINISHED,
  STEP_APPROVE_REJECTED,
  STEP_APPROVE_STARTED,
  STEP_FORM_FILL,
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  STEP_READY_PREVIEW,
  // STEP_READY_START_SWAP,
  // STEP_READY_TRANSACTION,
  STEP_SWITCH_NETWORK,
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_REJECTED,
  STEP_TRANSFER_START,
  STEP_UPDATE_RATES,
  // STEP_TRANSFER_START,
  // STEP_TRANSFER_PROCESS,
  TOKEN_DIRECTION_FROM,
} from "../../constants/TransferConstants";
import TransferProcess from "../../components/Swap/TransferProcess";
import ChooseTokenPopup from "../../components/Swap/Dialogs/ChooseTokenPopup";
import {
  BTN_KEY_APPROVE,
  BTN_KEY_APPROVE_RETRY,
  BTN_KEY_CHAIN_CHANGE,
  BTN_KEY_PREVIEW,
  BTN_KEY_RETURN_SWAP_FORM,
  BTN_KEY_TRANSFER,
  BTN_KEY_TRANSFER_RETRY,
  BTN_KEY_UPDATE_RATE,
  BTN_START_FROM_ROUTE,
} from "../../constants/SwapButtonConstants";
import SwapFormBock from "../../components/Swap/SwapFormBock";
import TransactionStepAlert from "../../components/Swap/TransactionStepAlert";
import { ALERT_WARNING } from "../../constants/AlertTypes";
import {
  closeNotification,
  showNotifaction,
} from "../../features/dialogs/notificationPopupSlice";
import SwapTitle from "../../components/Swap/SwapTitle";
import SwapFooter from "../../components/Swap/SwapFooter";
import PopupBlock from "../../components/Layout/Header/PopupBlock/PopupBlock";
import ConfirmationBlock from "../../components/Swap/ConfirmationBlock";
import { clearChooseToken, showChooseToken } from "../../features/dialogs/chooseTokenSlice";

export default function TransferForm() {
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );
  // let error = useSelector((state) => state.walletServiceProvider.error);

  let isPopupOpened = useSelector(
    (state) => state.walletServiceProvider.isPopupOpened
  );

  let chooseTokenState = useSelector((state) => state.chooseTokenManager);

  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );

  const [routes, setRoutes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [directType, setTokenDiretType] = useState(null);
  const [prevRoute, setPrevRoute] = useState({
    routeFrom: null,
    cryptoFrom: null,
    routeTo: null,
    cryptoTo: null,
  });

  const [transactionStep, setTransactionStep] = useState(STEP_FORM_FILL);

  const onProcessTransactions = useRef([]);
  const curTransId = useRef(null);

  // const [onProcessTransactions, setTransactionList] = useState([]);

  const [transactionData, setDataTransaction] = useState({
    chainId: 1,
    data: null,
    from: null,
    gasLimit: null,
    gasPrice: null,
    to: null,
    value: null,
    approvalAddress: null,
    transactionId: null,

    estimateSeconds: null,
    hopsCount: null,
    router: null,
    bridge: null,
    fee: null,
    exChangeService: null,
    calcFee: null,
    // amountFromUSD:null,
  });

  const [transactions, setTransactions] = useState([]);

  const [formData, setFormData] = useState({
    amountFrom: "",
    routeFrom: null,
    cryptoFrom: null,
    quoteTo: 0,
    quoteFrom: 0,
    amountTo: "",
    amountToUSD: 0,
    amountFromUSD: 0,
    routeTo: null,
    cryptoTo: null,
  });

  const [tokenBalance, setTokenBalance] = useState(0.0);
  const [tokenOutBalance, setTokenOutBalance] = useState(0.0);

  const clearFormAmount = (clearAmountFrom = true) => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        amountFrom: clearAmountFrom ? 0 : prevState.amountFrom,
        amountFromUSD: 0,
        amountTo: "",
        amountToUSD: 0,
      },
    }));
  };
  const resetForm = (clearAmountFrom) => {
    clearFormAmount(clearAmountFrom);
    setTransactionStep(STEP_FORM_FILL);
  };
  const fieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function openTokenSelector(type) {
    setTokenDiretType(type);
    let route =
      type === TOKEN_DIRECTION_FROM ? formData.routeFrom : formData.routeTo;
    dispatch(
      showChooseToken({
        routes: routes,
        directType: type,
        routeFrom: formData.routeFrom,
        cryptoFrom: formData.cryptoFrom,
        curRoute: route,
      })
    );
  }

  const dispatch = useDispatch();

  const storePrevRouteToken = () => {
    setPrevRoute({
      routeFrom: formData.routeFrom,
      cryptoFrom: formData.cryptoFrom,
      routeTo: formData.routeTo,
      cryptoTo: formData.cryptoTo,
    });
  };

  useEffect(() => {
    if (!chooseTokenState.show && chooseTokenState.selectedItem) {
      let selectedItem = chooseTokenState.selectedItem;
      closeTokenPopup(selectedItem);
      dispatch(clearChooseToken());
    }
  }, [chooseTokenState.show]);

  const closeTokenPopup = (selectedItem) => {
    // toggleTokenPopup(false);
    // setErrorMsg(null);
    if (selectedItem) {
      storePrevRouteToken();
      setFormData((prevState) => ({
        ...prevState,
        ...{
          routeTo:
            directType !== TOKEN_DIRECTION_FROM
              ? selectedItem.route
              : formData.routeTo,
          cryptoTo:
            directType !== TOKEN_DIRECTION_FROM
              ? selectedItem.token
              : formData.cryptoTo,

          amountFrom:
            directType === TOKEN_DIRECTION_FROM ? "" : formData.amountFrom,
          amountFromUSD:
            directType === TOKEN_DIRECTION_FROM ? 0 : formData.amountFromUSD,
          amountTo: "",
          amountToUSD: 0,

          routeFrom:
            directType === TOKEN_DIRECTION_FROM
              ? selectedItem.route
              : formData.routeFrom,
          cryptoFrom:
            directType === TOKEN_DIRECTION_FROM
              ? selectedItem.token
              : formData.cryptoFrom,
        },
      }));
    }
  };

  const loadRoutes = async () => {
    setLoading(true);
    let res = await getDataByUrl("router/networks-list");
    if (!res.hasError && res.data.success == undefined) {
      setRoutes(res.data);
    } else {
      setErrorMsg(res.errorText || res.data.message);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   setErrorMsg(error);
  // }, [error]);

  const setErrorMsg = (errorText) => {
    dispatch(closeNotification());
    if (errorText == null) {
      return;
    }
    if (errorText != null && errorText.startsWith(amountErrorText)) {
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorText })
      );
    } else {
      dispatch(
        showNotifaction({ alertType: ALERT_WARNING, caption: errorText })
      );
    }
  };

  useEffect(() => {
    onCryptoSelect();
    if (formData.cryptoFrom) setCryptoQuote(formData.cryptoFrom.id);
  }, [formData.routeFrom, formData.cryptoFrom]);

  useEffect(() => {
    resetForm(false);
    if (walletInfo.isConnected) {
      startLoadingQuota();
    }
  }, [
    formData.routeFrom,
    formData.cryptoFrom,
    formData.routeTo,
    formData.cryptoTo,
    formData.amountFrom,
    walletInfo.isConnected,
  ]);

  useEffect(() => {
    if (formData.cryptoTo && formData.amountTo > 0) {
      setCryptoQuote(formData.cryptoTo.id, false);
    }
  }, [formData.amountTo, formData.cryptoTo]);

  const startLoadingQuota = async () => {
    setTimeLeft(0);
    if (!formData.routeFrom) {
      return;
    }
    cancelLoadingQuota();
    setUsdQuota();
    setErrorMsg(null);

    if (walletInfo.networkChainId != formData.routeFrom.chainId) {
      setTransactionStep(STEP_SWITCH_NETWORK);
      return;
    }
    if (isAmountLess(formData.amountFrom * (formData.quoteFrom || 0))) {
      setTransactionStep(STEP_FORM_FILL);
      setErrorMsg(amountErrorText);
      return;
    }

    if (formData.amountFrom == null || formData.amountFrom == 0) {
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    if (parseFloat(tokenBalance) >= parseFloat(formData.amountFrom)) {
      setTransactionStep(STEP_LOAD_QUOTA);
      loadQuota();
    }
  };

  const setUsdQuota = () => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        amountFromUSD: (
          (formData.amountFrom || 0) * formData.quoteFrom
        ).toFixed(4),
      },
    }));
  };

  const cancelLoadingQuota = () => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
  };

  const onCryptoSelect = () => {
    getTokenAmount();
  };

  const handleChainChange = async () => {
    let isChanged = await MetamaskWebProvider.changeChain(
      walletInfo,
      dispatch,
      formData.routeFrom
    );
    if (isChanged) {
      getTokenAmount();
    } else {
      // showStepPopup(TYPE_REJECTED)
      // setTokenBalance(0);
    }
  };

  useEffect(() => {
    loadRoutes();
  }, []);

  useEffect(() => {
    if (walletInfo.networkChainId && formData.routeFrom) {
      if (walletInfo.networkChainId != formData.routeFrom.chainId) {
        setTransactionStep(STEP_SWITCH_NETWORK);
      } else {
        setTransactionStep(STEP_FORM_FILL);
        startLoadingQuota();
        // if(formData.amountFrom && formData.amountFrom > 0){
        //   loadQuota();
        // }else{
        //   setTransactionStep(STEP_FORM_FILL);
        // }
      }
      getTokenAmount();
    }
    if (!walletInfo.networkChainId) {
      setTokenBalance(0.0);
    }
    if (!walletInfo.networkChainId) {
      setTransactionStep(STEP_FORM_FILL);
      clearFormAmount();
    }
  }, [walletInfo.networkChainId]);

  useEffect(() => {
    onRouteLoad();
  }, [routes]);

  useEffect(() => {
    setTokenOutAmount();
  }, [formData.cryptoTo]);

  const getDefaultRoute = (chainId) => {
    let defaultRoute = null;
    if (routes.some((v) => v.chainId == chainId)) {
      defaultRoute = routes.find((v) => v.chainId == chainId);
    }
    if (!defaultRoute) {
      defaultRoute = routes.length > 0 ? routes[0] : null;
    }
    return defaultRoute;
  };

  const onRouteLoad = () => {
    if (routes.length == 0) return;

    let routeFrom;
    let routeTo;

    if (walletInfo.networkChainId) {
      routeFrom = routes.find(
        (v) => parseInt(v.chainId) === parseInt(walletInfo.networkChainId)
      );
      if (!routeFrom) {
        routeFrom = getDefaultRoute(ETH_CHAIN_ID);
      }
      const hasDefaultRoute = routes.find((v) =>
        [BSC_CHAIN_ID, ETH_CHAIN_ID].includes(v.chainId)
      );
      if (!formData.routeTo) {
        if (hasDefaultRoute) {
          routeTo = routes.find(
            (v) =>
              parseInt(v.chainId) ==
              (walletInfo.networkChainId == BSC_CHAIN_ID
                ? ETH_CHAIN_ID
                : BSC_CHAIN_ID)
          );
        }
        if (!routeTo) {
          routeTo = routes[0];
        }
      }
    } else {
      routeFrom = getDefaultRoute(ETH_CHAIN_ID);
      routeTo = getDefaultRoute(BSC_CHAIN_ID);
    }
    if (routeFrom && routeTo) {
      let cryptoFrom =
        prevRoute.routeTo && prevRoute.routeTo.id == routeFrom.id
          ? prevRoute.cryptoTo
          : routeFrom.cryptos.find((v) => v.symbol === DEFAULT_TOKEN_SYMBOL);
      let cryptoTo = routeTo.cryptos.find(
        (v) => v.symbol === DEFAULT_TOKEN_SYMBOL
      );
      if (cryptoFrom.id == cryptoTo.id) {
        cryptoTo = routeTo.cryptos.find(
          (v) => v.symbol === DEFAULT_TOKEN_SYMBOL2
        );
      }

      // console.log(prevRoute.routeFrom,prevRoute.routeFrom && prevRoute.routeFrom.id == routeFrom.id,formData.cryptoTo,'formData.cryptoTo');
      setFormData((prevState) => ({
        ...prevState,
        ...{
          cryptoFrom: cryptoFrom,
          routeFrom: routeFrom,
          cryptoTo: formData.cryptoTo == null ? cryptoTo : formData.cryptoTo,
          routeTo: formData.routeTo == null ? routeTo : formData.routeTo,
          amountFrom: "",
        },
      }));
    }
  };

  const replaceNetwork = () => {
    storePrevRouteToken();

    setFormData((prevState) => ({
      ...prevState,
      ...{
        cryptoFrom: formData.cryptoTo,
        routeFrom: formData.routeTo,
        cryptoTo: formData.cryptoFrom,
        routeTo: formData.routeFrom,
        amountFrom: formData.amountTo,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const calcGasFee = async (gasLimit) => {
    let amount = 0;
    let amountInUsd = 0;
    try {
      let gasPrice = await MetamaskWebProvider.getGasPrice();
      const nativeCrypto = formData.routeFrom.cryptos.find((v) =>
        isZeroAddress(v.contractAddress)
      );
      const roundDecimal = 4;
      amount = gasPrice
        .mul(BigNumber.from(gasLimit))
        .div(BigNumber.from(10).pow(nativeCrypto.decimals - roundDecimal));

      amount = parseFloat(ethers.utils.formatUnits(amount, roundDecimal));
      console.log(amount, "amount");
      const usdCourse = await getCryptoInUSD(nativeCrypto.id);
      amountInUsd = usdCourse.hasError ? 0 : amount * usdCourse.data;
    } catch (error) {
      console.log(error, "error calcGasFee");
    }
    return { amountInNative: amount, amountInUsd: amountInUsd };
  };

  const setTransactionInfo = async (transferInfo) => {
    // return;
    if (walletInfo.networkChainId == formData.routeFrom.chainId) {
      setTransactionStep(STEP_READY_PREVIEW);
    } else {
      setTransactionStep(STEP_SWITCH_NETWORK);
    }
    let feeData = await calcGasFee(transferInfo.transferGasLimit);
    console.log(feeData, "feeData");
    setDataTransaction((prevState) => ({
      ...prevState,
      ...{
        approvalAddress: transferInfo.approvalAddress,
        transactionId: transferInfo.id,
        chainId: transferInfo.transferChainId,
        data: transferInfo.transferData,
        from: transferInfo.transferFromAddress,
        gasLimit: transferInfo.transferGasLimit,
        gasPrice: transferInfo.transferGasPrice,
        to: transferInfo.transferToAddress,
        value: transferInfo.transferAmount,

        estimateSeconds: transferInfo.estimateTransferSeconds,
        hopsCount: transferInfo.hopsCount,
        router: transferInfo.router,
        bridge: transferInfo.bridge ? transferInfo.bridge : null,
        fee: transferInfo.fee,
        calcFee: feeData,
      },
    }));

    setFormData((prevState) => ({
      ...prevState,
      amountTo: parseFloat(transferInfo.estimateToAmount).toFixed(4), // parseFloat(utils.formatUnits(transferInfo.estimate.toAmount,transferInfo.action.toToken.decimals)).toFixed(4),
      // amountToUSD: transferInfo.amountInUsd,
      // amountFromUSD:transferInfo.amountFromUsd
    }));
  };

  const cancelTokenSource = useRef();

  const calcTransferAmountOut = () => {
    if (!isZeroAddress(formData.cryptoFrom.contractAddress)) {
      return formData.amountFrom;
    }
    const gasAmountInUsd =
      formData.routeFrom.chainId == ETH_CHAIN_ID
        ? GAS_AMOUNT_USD_ETHER
        : GAS_AMOUNT_USD;

    const balanceInUsd = tokenBalance * formData.quoteFrom;
    let amountFromUSD = formData.amountFrom * formData.quoteFrom;

    let amount = formData.amountFrom;
    if (
      amountFromUSD > balanceInUsd - gasAmountInUsd &&
      balanceInUsd > gasAmountInUsd
    ) {
      amount -=
        (amountFromUSD - (balanceInUsd - gasAmountInUsd)) / formData.quoteFrom;
    }

    return amount;
  };
  const calcMetamaskVal = () => {
    const amount2 = calcTransferAmountOut();
    const amount3 = formatAmountToUint(
      amount2,
      formData.cryptoFrom.decimals
    ).toString();
    return isZeroAddress(formData.cryptoFrom.contractAddress)
      ? numberToHex(amount3)
      : "0x00";
  };

  const loadQuota = async () => {
    // event.preventDefault();
    setErrorMsg(null);
    setTransactionStep(STEP_LOAD_QUOTA);
    // return;
    if (!isValidFormData()) {
      setErrorMsg("Form Data invalid.Please fill amount");
      setTransactionStep(STEP_FORM_FILL);
      return;
    }

    cancelTokenSource.current = axios.CancelToken.source();
    try {
      //minus amount from native tokens
      const amount = calcTransferAmountOut();
      if (amount < 0) {
        setErrorMsg("Amount is to small to transfer");
        setTransactionStep(STEP_FORM_FILL);
        return;
      }
      let resData = await transferStart(
        amount,
        formData.routeFrom,
        formData.routeTo,
        formData.cryptoFrom,
        formData.cryptoTo,
        walletInfo.accountAddress,
        cancelTokenSource.current.token
      );
      if (resData.hasError) {
        if (!resData.isCanceled) {
          setTransactionStep(STEP_FORM_NO_ROUTE_FOUND);
          setErrorMsg(resData.errorText);
        }
      } else {
        const data = resData.data;
        let amount = resData.data.transferAmount;
        data.transferAmount = calcMetamaskVal();
        setTransactionInfo(data);
        setErrorMsg(null);
        setTimeLeft(MAX_ROUTE_REFRESH_TIME);
      }
    } catch (error) {
      const res = error.response;
      console.log(error, res, "res");
      if (res && res.status == 400) {
        setErrorMsg(res.data.message);
      } else {
        setErrorMsg("ERROR ON RESPONSE OF SERVER");
      }
      setTransactionStep(STEP_FORM_FILL);
      // console.log(error,'error')
    }
  };

  const setMaxAmount = async () => {
    setFormData((prevState) => ({
      ...prevState,
      amountFrom: tokenBalance,
    }));
  };

  const getTokenAmount = async () => {
    let balance = 0;
    if (!walletInfo.isConnected || !formData.routeFrom || !formData.cryptoFrom)
      return balance;
    try {
      balance = await getTokenBalance(
        walletInfo.providerType,
        walletInfo.accountAddress,
        formData.routeFrom,
        formData.cryptoFrom,
        walletInfo.networkChainId
      );
      // balance = await getBalanceByJsonRpc(
      //   formData.routeFrom.rpcUrls[0],
      //   formData.cryptoFrom.contractAddress,
      //   formData.cryptoFrom.decimals,
      //   walletInfo.accountAddress
      // );
      // setTokenBalance(balance);
    } catch (error) {
      setErrorMsg("Error on geting Balance");
    }
    setTokenBalance(balance);
    return balance;
  };

  const setCryptoQuote = async (cryptoId, isFrom = true) => {
    const usdCourseInfo = await getCryptoInUSD(cryptoId);
    // console.log(!usdCourseInfo.hasError, "usdCourseInfo.hasError");
    if (!usdCourseInfo.hasError)
      setFormData((prevState) => ({
        ...prevState,
        ...{
          quoteFrom: isFrom ? usdCourseInfo.data : formData.quoteFrom,
          quoteTo: !isFrom ? usdCourseInfo.data : formData.quoteTo,
          amountToUSD: !isFrom
            ? (formData.amountTo * usdCourseInfo.data).toFixed(4)
            : formData.amountToUSD,
        },
      }));
  };

  const setTokenOutAmount = async () => {
    if (!walletInfo.isConnected || !formData.routeTo) return;
    let balance = 0;
    try {
      balance = await getTokenBalance(
        walletInfo.providerType,
        walletInfo.accountAddress,
        formData.routeTo,
        formData.cryptoTo,
        walletInfo.networkChainId
      );
      // let balance = await getBalanceByJsonRpc(
      //   formData.routeTo.rpcUrls[0],
      //   formData.cryptoTo.contractAddress,
      //   formData.cryptoTo.decimals,
      //   walletInfo.accountAddress
      // );
      // setTokenOutBalance(balance);
    } catch (error) {
      setErrorMsg("Error on get Balance");
    }
    setTokenOutBalance(balance);
  };

  const isValidFormData = () => {
    return (
      parseFloat(formData.amountFrom) > 0 &&
      formData.cryptoFrom != null &&
      formData.cryptoTo != null
    );
  };
  const beforApprove = (isRetry = false) => {
    setTransactionStep(STEP_APPROVE_STARTED);
    setTimeLeft(0);
    if (!isRetry) {
      onInitTransaction();
    } else {
      handleTransactionProcess(STEP_APPROVE_STARTED, null, true);
    }
  };

  const afterApprove = async (tx = null) => {
    setTransactionStep(STEP_APPROVE_APPROVED);
    handleTransactionProcess(STEP_APPROVE_APPROVED, tx ? tx.hash : null);
    setApprove(transactionData.transactionId, tx ? tx.hash : null);
    console.log(tx, "tx");
    if (tx) {
      let res = await tx.wait();
      console.log(res, "res");
      // setDataTransaction((prevState) => ({
      //   ...prevState,
      //   ...{ approveHash: tx.hash },
      // }));
    }

    setTransactionStep(STEP_APPROVE_FINISHED);
    handleTransactionProcess(STEP_APPROVE_FINISHED);
    // sendTransaction();
  };

  const handleTransactionProcess = (step = null, txHash = null) => {
    // console.log(onProcessTransactions.current,step, transactionData.transNum, "step");
    onProcessTransactions.current = [
      ...onProcessTransactions.current.map((item) => {
        if (item.id == curTransId.current) {
          console.log(item, "item");
          if (step != null) {
            item.state = step;
          }
          if (step == STEP_APPROVE_REJECTED) {
            item.isApproved = false;
          }
          if (step == STEP_APPROVE_APPROVED) {
            if (txHash) {
              item.approveTx = txHash;
            }
            item.isApproved = true;
          }
          if (step == STEP_TRANSFER_APPROVED) {
            if (txHash) {
              item.transfetTx = txHash;
            }
            item.isTransferApproved = true;
          }
          console.log(step, STEP_TRANSFER_FINISHED, "STEP_TRANSFER_FINISHED");
          if (step == STEP_TRANSFER_FINISHED) {
            item.isFinished = true;
            console.log(item, "item.isFinished");
          }
          return item;
        } else {
          return item;
        }
      }),
    ];
    // console.log(onProcessTransactions.current,step, "step end");
    // setTransactionList(nextOnProcessTransactions);
  };

  const onInitTransaction = () => {
    const newNum = Date.now();

    curTransId.current = newNum;

    onProcessTransactions.current = [
      ...onProcessTransactions.current,
      {
        id: newNum,
        state: STEP_APPROVE_STARTED,
        isApproved: false,
        approveTx: null,
        isTransferApproved: false,
        transfetTx: null,
        isFinished: false,
      },
    ];
  };

  const approveTransfer = async (isRetry = false) => {
    beforApprove(isRetry);

    setErrorMsg(null);

    let amount = formatAmountToUint(
      calcTransferAmountOut(),
      formData.cryptoFrom.decimals
    ); // ethers.utils.parseUnits(formData.amountFrom.toString(),formData.cryptoFrom.decimals);

    const info = await approveWalletTransaction(
      amount,
      walletInfo,
      formData,
      transactionData
    );
    console.log(info, "info");

    if (info.isApproved) {
      afterApprove(info.networkResp);
    } else {
      onApproveRejected();
    }

    // return ;

    // Transactions with the native token don't need approval

    // if (!formData.cryptoFrom) {
    //   setErrorMsg("Please select crypto");
    // }
  };

  const onApproveRejected = () => {
    setTransactionStep(STEP_APPROVE_REJECTED);
    handleTransactionProcess(STEP_APPROVE_REJECTED, null);
  };

  const saveTransactionInLocalStorage = (txHash) => {
    let transactions = JSON.parse(localStorage.getItem("transactions"));
    if (!transactions) transactions = [];
    transactions.push(txHash);
    localStorage.setItem("transactions", JSON.stringify(transactions));
  };

  let [checkTime, setCheckTime] = useState(0);

  const getTransactionStatus = async (transData) => {
    let statusInfo = await getStatus(transData.transactionId);
    if (!statusInfo.hasError) {
      transData.status = statusInfo.data.status;
      transData.txHash = statusInfo.data.txHash;
      transData.txLink = statusInfo.data.txLink;
    }
    return transData;
  };

  const sendTransaction = async () => {
    setTransactionStep(STEP_TRANSFER_START);
    handleTransactionProcess(STEP_TRANSFER_START);

    const transData = await sendWalletTransaction(
      walletInfo,
      transactionData,
      dispatch
    );
    if (!transData) {
      setErrorMsg("Transaction is not provided");
      return;
    }
    if (transData && !transData.hasError) {
      afterTransferApproved(transactionData.transactionId, transData.txHash);
    } else {
      setTransactionStep(STEP_TRANSFER_REJECTED);
      handleTransactionProcess(STEP_TRANSFER_REJECTED);
    }
  };

  const afterTransferApproved = async (transactionId, txHash) => {
    setTransactionStep(STEP_TRANSFER_APPROVED);
    handleTransactionProcess(STEP_TRANSFER_APPROVED, txHash);
    setHash(transactionId, txHash);

    await mintTransaction(txHash);
    setTransactionStep(STEP_TRANSFER_FINISHED);
    handleTransactionProcess(STEP_TRANSFER_FINISHED);
  };

  const mintTransaction = async (txHash) => {
    if (!formData.routeFrom) return;
    const Provider2 = new ethers.providers.JsonRpcProvider(
      formData.routeFrom.rpcUrls[0]
    );
    let mint = await Provider2.waitForTransaction(txHash);
  };

  const returnSwapForm = () => {
    setTransactionStep(STEP_READY_PREVIEW);
  };

  const returnSwapWithAmount = () => {
    startLoadingQuota();
  };

  const updateRateAndQuota = async () => {
    setTransactionStep(STEP_FORM_FILL);
    setFormData((prevState) => ({
      ...prevState,
      amountTo: "",
    }));
    await setCryptoQuote(formData.cryptoFrom.id);
    await setCryptoQuote(formData.cryptoTo.id, false);
    startLoadingQuota();
  };

  const gotoPreview = () => {
    handleTransactionProcess(STEP_READY_APPROVE, null);
    setTransactionStep(STEP_READY_APPROVE);
  };

  const btnHandler = (key) => {
    switch (key) {
      case BTN_KEY_CHAIN_CHANGE:
        handleChainChange();
        break;
      case BTN_KEY_PREVIEW:
        gotoPreview();
        break;
      case BTN_KEY_APPROVE:
        approveTransfer();
        break;
      case BTN_KEY_UPDATE_RATE:
        updateRateAndQuota();
        break;
      case BTN_KEY_APPROVE_RETRY:
        approveTransfer(true);
        break;
      case BTN_KEY_TRANSFER:
        sendTransaction();
        break;
      case BTN_KEY_TRANSFER_RETRY:
        retryTransfer();
        break;
      case BTN_KEY_RETURN_SWAP_FORM:
        returnSwapForm();
        break;
      case BTN_START_FROM_ROUTE:
        returnSwapWithAmount();
        break;

      default:
        break;
    }
  };

  const retryTransfer = () => {
    setFormData((prevState) => ({
      ...prevState,
      ...{
        quoteTo: 0,
        amountTo: "",
        amountToUSD: "",
      },
    }));
    returnSwapWithAmount();
  };
  let process = onProcessTransactions.current.find(
    (v) => v.id == curTransId.current
  );

  // console.log(process,'process transferfom')
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!timeLeft) {
      if (transactionStep == STEP_READY_APPROVE) {
        setTransactionStep(STEP_UPDATE_RATES);
        // startLoadingQuota();
      }
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const isPopupOpen =
    chooseTokenState.show || isPopupOpened || notificationState.show;

  const isShowForm = [
    STEP_FORM_FILL,
    STEP_LOAD_QUOTA,
    STEP_UPDATE_RATES,
    STEP_READY_PREVIEW,
    STEP_SWITCH_NETWORK,
    STEP_FORM_NO_ROUTE_FOUND,
  ].includes(transactionStep);

  const isShowConfirm = [STEP_READY_APPROVE].includes(transactionStep);

  const isTransactionProgress = [
    STEP_APPROVE_STARTED,
    STEP_APPROVE_APPROVED,
    STEP_APPROVE_REJECTED,
    STEP_APPROVE_FINISHED,
    STEP_TRANSFER_START,
    STEP_TRANSFER_APPROVED,
    STEP_TRANSFER_REJECTED,
    STEP_TRANSFER_FINISHED,
  ].includes(transactionStep);

  // console.log(transactionStep,showTokentPopup,isPopupOpened,notificationState.show,'notificationState.show')
  return (
    <>
      {!isPopupOpen && (
        <>
          {isShowForm && (
            <div className="widget__content">
              <SwapTitle />
              <SwapFormBock
                isLoading={isLoading}
                handleSubmit={handleSubmit}
                formData={formData}
                replaceNetwork={replaceNetwork}
                fieldChange={fieldChange}
                tokenBalance={tokenBalance}
                openTokenSelector={openTokenSelector}
                setMaxAmount={setMaxAmount}
                tokenOutBalance={tokenOutBalance}
                transactionData={transactionData}
                transactionStep={transactionStep}
                btnHandler={btnHandler}
                timeLeft={timeLeft}
                startLoadingQuota={startLoadingQuota}
              />
              <SwapFooter />
            </div>
          )}
          {isShowConfirm && (
            <ConfirmationBlock
              formData={formData}
              transactionData={transactionData}
              transactionStep={transactionStep}
              btnHandler={btnHandler}
            />
          )}
          {walletInfo.isConnected && isTransactionProgress && (
            <TransactionStepAlert
              formData={formData}
              step={transactionStep}
              process={process}
              closePopup={(btnkey) => {
                console.log(btnkey, "btnkey");
                if (btnkey) {
                  btnHandler(btnkey);
                }
              }}
            />
          )}
        </>
      )}
      <PopupBlock />
    </>
  );
}
