import { useMemo } from "react";
import { useSelector } from "react-redux";
import { amountErrorText } from "../../constants/TransferConstants";
import { getNetworkImageByChainId, getTokenImg } from "../../utils/NetworkList";
import { isAmountLess } from "../../utils/TransferApiUtil";

export default function SwapForm({
  formData,
  replaceNetwork,
  fieldChange,
  tokenBalance,
  openTokenSelector,
  setMaxAmount,
  tokenOutBalance,
}) {
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );
  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );

  const onlyNumber = (e) => {
    if (!/[0-9]|\./.test(e.key)) {
      e.preventDefault();
    }
  };

  const isBadAmount = useMemo(() => {
    if (
      notificationState.show &&
      notificationState.caption.startsWith(amountErrorText)
    ) {
      return true;
    }
    if (!walletInfo.isConnected) return false;

    if (formData.amountFrom == null) return false;

    if (isAmountLess(formData.amountFromUSD)) return true;

    return parseFloat(formData.amountFrom) > parseFloat(tokenBalance);
  }, [
    notificationState,
    walletInfo.isConnected,
    formData.amountFrom,
    formData.amountFromUSD,
    tokenBalance,
    amountErrorText,
  ]);

  return (
    <div className="widget-form__main-inputs">
      <button
        className="widget-form__change-fields-button"
        onClick={replaceNetwork}
      >
        <img src="./img/change-fields-icon.svg" alt="" />
      </button>

      <div className="widget-form__main-input widget-form__main-input_from">
        <div className="widget-form__main-input-head">
          <span className="widget-form__main-input-name">From</span>
        </div>
        <button
          className="choose-token-button"
          onClick={() => openTokenSelector("From")}
        >
          {formData.cryptoFrom && (
            <>
              <div className="choose-token-button__icons">
                <img
                  className="choose-token-button__icon choose-token-button__icon_token"
                  src={getTokenImg(formData.cryptoFrom)}
                  alt=""
                />
                <img
                  className="choose-token-button__icon choose-token-button__icon_network"
                  src={getNetworkImageByChainId(formData.routeFrom.chainId)}
                  alt=""
                />
              </div>
              <div className="choose-token-button__value">
                <span className="choose-token-button__token">
                  {formData.cryptoFrom.symbol}
                </span>
                <span className="choose-token-button__network">
                  {formData.routeFrom.name}
                </span>
              </div>
            </>
          )}
        </button>
        <div className="widget-form__main-input-balance">
          <span className="widget-form__balance-title">Balance:</span>
          <span className="widget-form__balance-value">{tokenBalance}</span>
        </div>
        <div className="widget-form__main-input-content">
          <input
            type="text"
            onKeyPress={(e) => onlyNumber(e)}
            placeholder="Enter amount"
            size="1"
            name="amountFrom"
            className={isBadAmount ? "overbalance" : ""}
            value={formData.amountFrom}
            onChange={fieldChange}
          />
        </div>
        <div className="widget-form__subline">
          <span className="widget-form__equivalent">
            ${formData.amountFromUSD}
          </span>
          <button
            className="max-btn"
            disabled={!walletInfo.isConnected}
            onClick={() => setMaxAmount()}
          >
            Max
          </button>
        </div>
        {notificationState.show &&
          notificationState.caption.startsWith(amountErrorText) && (
            <div className="error_block">
              <span>{notificationState.caption}</span>
            </div>
          )}
      </div>
      <div className="widget-form__main-input widget-form__main-input_to">
        <div className="widget-form__main-input-head">
          <span className="widget-form__main-input-name">To (Estimated)</span>
        </div>
        <button
          className="choose-token-button"
          onClick={() => openTokenSelector("To")}
        >
          {formData.cryptoTo && (
            <>
              <div className="choose-token-button__icons">
                <img
                  className="choose-token-button__icon choose-token-button__icon_token"
                  src={getTokenImg(formData.cryptoTo)}
                  alt=""
                />
                <img
                  className="choose-token-button__icon choose-token-button__icon_network"
                  src={getNetworkImageByChainId(formData.routeTo.chainId)}
                  alt=""
                />
              </div>
              <div className="choose-token-button__value">
                <span className="choose-token-button__token">
                  {formData.cryptoTo.symbol}
                </span>
                <span className="choose-token-button__network">
                  {formData.routeTo.name}
                </span>
              </div>
            </>
          )}
        </button>
        <div className="widget-form__main-input-balance">
          <span className="widget-form__balance-title">Balance:</span>
          <span className="widget-form__balance-value">{tokenOutBalance}</span>
        </div>
        <div className="widget-form__main-input-content widget-form__main-input-content_to">
          <input
            type="text"
            onKeyPress={(e) => onlyNumber(e)}
            readOnly
            placeholder="You'll receive"
            size="1"
            name="amountTo"
            value={formData.amountTo}
            onChange={fieldChange}
          />
        </div>
        <div  className="widget-form__subline">
          <span  className="widget-form__equivalent">${formData.amountToUSD}</span>
        </div>
      </div>
    </div>
  );
}
