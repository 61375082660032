import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  POPULAR_TOKENS,
  TOKEN_DIRECTION_FROM,
} from "../../../constants/TransferConstants";
import { closeChooseToken } from "../../../features/dialogs/chooseTokenSlice";
import { showSwitchNetworkDialog } from "../../../features/dialogs/switchNetworkSlice";
import { getWalletTokenAmounts } from "../../../features/walletService/balanceService";
import { isMobileDevice } from "../../../utils/BrowserUtil";
import {
  getNetworkImageByChainId,
  getTokenImg,
} from "../../../utils/NetworkList";
import {
  getAvialableNetworks,
  getConnections,
} from "../../../utils/TransferApiUtil";

export default function ChooseTokenPopup() {
  const [filtertext, setFilterText] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [avaliableNetworks, setAvialableNetworks] = useState([]);
  const lastLoadTokenId = useRef([]);
  const loadedBalances = useRef([]);
  const chooseTokenPopupElement = useRef();

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  let chooseTokenState = useSelector(
    (state) => state.chooseTokenManager
  );


  function filterChange(str) {
    setFilterText(str);
  }

  let dispatch = useDispatch();

  useEffect(() => {
    if (chooseTokenState.show) {
      setTokenList([]);
      loadAvialableNetworkList();
      setSelectedRoute(chooseTokenState.curRoute);
      document.body.classList.add("fixed");
    } else {
      setSelectedRoute(null);
      document.body.classList.remove("fixed");
    }
  }, [chooseTokenState.show]);

  useEffect(() => {
    if (selectedRoute) {
      makeCryptoList();
    }
  }, [selectedRoute]);

  const cancelTokenSource = useRef();

  const checkConnection = async () => {
    if (chooseTokenState.directType == TOKEN_DIRECTION_FROM) return [];

    cancelLoading();

    cancelTokenSource.current = axios.CancelToken.source();
    let data = await getConnections(
      chooseTokenState.routeFrom.id,
      chooseTokenState.cryptoFrom.id,
      selectedRoute.id,
      cancelTokenSource.current.token
    );
    return data.hasError ? [] : data.data;
  };

  const isMobile = isMobileDevice();

  const makeMobileNetworks = (networks, selRoute) => {
    if (!isMobileDevice() || networks.length == 0) {
      return networks;
    }
    let list = [];
    if (selRoute) {
      list.push(selRoute);
    }
    list = [
      ...list,
      ...networks.filter(
        (v, index) =>
          index < 4 &&
          (list.length == 0 || (list[0] && v.chainId != list[0].chainId))
      ),
    ];
    return list;
  };

  // const filteredAvialableNetworks = useMemo(
  //   () =>  makeMobileNetworks(avaliableNetworks, selectedRoute)
  //   ,[avaliableNetworks, selectedRoute]
  // );

  const loadAvialableNetworkList = async () => {
    if (chooseTokenState.directType == TOKEN_DIRECTION_FROM) {
      setAvialableNetworks([...chooseTokenState.routes]);
      return;
    }

    cancelLoading();

    let data = await getAvialableNetworks(
      chooseTokenState.routeFrom.id,
      chooseTokenState.cryptoFrom.id
    );
    let networks = data.hasError ? [] : data.data;
    return setAvialableNetworks(
      chooseTokenState.routes.filter(
        (route) => networks.findIndex((v) => v.id == route.id) != -1
      )
    );
  };

  const cancelLoading = () => {
    if (cancelTokenSource.current) cancelTokenSource.current.cancel();
  };

  const updateTokenList = (tokenList = []) => {
    setTokenList(sorrtingList(tokenList));
    setLoading(false);
  };

  const makeCryptoList = async () => {
    setLoading(true);
    const uniqueId =
      Math.floor(Math.random() * 100) + ":" + selectedRoute.chainId;
    lastLoadTokenId.current = uniqueId;
    const allowedCryptos = await checkConnection();
    let list = [];
    if (
      chooseTokenState.directType != TOKEN_DIRECTION_FROM &&
      allowedCryptos.length == 0
    ) {
      updateTokenList();
      return;
    }

    let networkBalanceList = loadedBalances.current.find(
      (v) =>
        v.walletAddress == walletInfo.accountAddress &&
        v.networkChainId == selectedRoute.chainId
    );

    selectedRoute.cryptos.forEach((crypto) => {
      let balance = "";
      let isBalanceLoaded = false;
      if (networkBalanceList) {
        let token = networkBalanceList.tokens.find(
          (v) => v.contractAddress == crypto.contractAddress
        );
        if (token) {
          balance = token.balance;
          isBalanceLoaded = true;
        }
      }

      const connectionIndex = allowedCryptos.findIndex(
        (v) => v.key == crypto.id
      );
      if (
        chooseTokenState.directType != TOKEN_DIRECTION_FROM &&
        allowedCryptos.findIndex((v) => v.key == crypto.id) == -1
      ) {
        return;
      }
      list.push({
        id: crypto.id,
        logoURI: crypto.logoURI,
        name: crypto.name,
        symbol: crypto.symbol,
        balance: balance || 0,
        decimals: crypto.decimals,
        contractAddress: crypto.contractAddress,
        isBalanceLoaded: isBalanceLoaded,
        formatedBalance: balance != 0 ? balance : null,
        isLiqvid:
          connectionIndex > -1 ? allowedCryptos[connectionIndex].isLiqvid : 0,
      });
    });
    setLoading(false);
    updateTokenList(list);
    if (walletInfo && walletInfo.isConnected && !networkBalanceList) {
      list = await loadBalancesFromNetwork(list, selectedRoute);
      if (lastLoadTokenId.current == uniqueId) updateTokenList(list);
    }
  };

  const loadBalancesFromNetwork = async (list, curRoute) => {
    let networkBalances = await getWalletTokenAmounts(
      curRoute,
      list,
      walletInfo.accountAddress
    );
    loadedBalances.current.push(networkBalances);
    return list;
  };

  function setRoute(route) {
    if (route.chainId != selectedRoute.chainId) {
      setSelectedRoute(route);
      setTokenList([]);
    }
  }

  const sorrtingList = (inTokens) => {
    let tokens = [];
    tokens = [
      ...inTokens
        .filter((v) => v.balance != 0)
        .sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance)),
    ];

    POPULAR_TOKENS.forEach((symbol) => {
      let sourceIndx = inTokens.findIndex((item) => item.symbol == symbol);
      if (
        sourceIndx != -1 &&
        tokens.findIndex((item2) => item2.symbol == symbol) == -1
      ) {
        tokens.push(inTokens[sourceIndx]);
      }
    });

    tokens.push(
      ...inTokens
        .filter(
          (item) => tokens.findIndex((item2) => item.id == item2.id) == -1
        )
        .sort((a, b) => {
          if (a.symbol < b.symbol) {
            return -1;
          }
          if (a.symbol > b.symbol) {
            return 1;
          }
          return 0;
        })
    );
    return tokens;
  };

  if(!chooseTokenState.show){
    return;
  }

  return (
      <div
        className="widget-popup swap-popup"
        id="chooseTokenPopup"
        ref={chooseTokenPopupElement}
      >
        <div className="widget-popup__header swap-popup__header">
          <button
            className="widget-popup__back-btn"
            onClick={() => dispatch(closeChooseToken())}
          >
            {" "}
            Back
          </button>
          <h3 className="widget-popup__title">
            Swap {chooseTokenState.directType == TOKEN_DIRECTION_FROM ? "from" : "to"}
          </h3>
        </div>

        <div className="swap-popup__search">
          <img src="img/search-icon.svg" width="14" height="14" alt="" />
          <input
            className="swap-popup__search-input"
            type="text"
            placeholder="Enter token name or contract address"
            value={filtertext}
            onChange={(event) => filterChange(event.target.value)}
          />
        </div>
        <div className="swap-popup__tokens-cnt">
          <div className="swap-popup__source-tokens">
            {avaliableNetworks
              .sort((a, b) => parseFloat(a.chainId) - parseFloat(b.chainId))
              .map((route) => (
                <a
                  className={`swap-popup__source-tokens-item ${
                    selectedRoute && selectedRoute.chainId == route.chainId
                      ? "active"
                      : ""
                  }`}
                  key={route.id}
                  onClick={() => {
                    if (selectedRoute.chainId != route.chainId) setRoute(route);
                  }}
                >
                  <img
                    src={getNetworkImageByChainId(route.chainId)}
                    alt={route.name}
                    width="24px"
                  />
                  {route.name}
                </a>
              ))}
          </div>

          <div className="swap-popup__tokens-list">
            {isLoading && (
              <div className="swap-popup__loading">
                <span className="loader-violet"></span>
                <span>Loading...</span>
              </div>
            )}
            {!isLoading &&
              selectedRoute &&
              tokenList.filter((v) =>
                v.symbol.toLowerCase().startsWith(filtertext.toLowerCase())
              ).length === 0 && (
                <div className="swap-popup__tokens-list-empty">
                  <img src="img/empty-list-icon.svg" alt="" />
                  <span>Token not found</span>
                  <span>
                    Try again with a different wording, or change the network
                  </span>
                </div>
              )}

            <div className="swap-popup__all-tokens">
              {selectedRoute &&
                tokenList
                  .filter((v) =>
                    v.symbol.toLowerCase().startsWith(filtertext.toLowerCase())
                  )
                  // .sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance))
                  .map((token) => (
                    <div
                      className="tokens-list-item"
                      key={token.id}
                      onClick={() => {
                        setFilterText("");
                        dispatch(closeChooseToken({
                          route: selectedRoute,
                          token: token,
                        }))
                      }}
                    >
                      <div className="tokens-list-item__left">
                        <img
                          className="tokens-list-item__logo"
                          src={getTokenImg(token)}
                          alt=""
                        />
                        <p className="tokens-list-item__title">
                          <span className="tokens-list-item__name">
                            {token.symbol}
                          </span>
                          <span className="tokens-list-item__network">
                            on {selectedRoute.name} Network
                          </span>
                        </p>
                      </div>
                      <div className="tokens-list-item__right">
                        <span className="tokens-list-item__value">
                          {token.formatedBalance}
                        </span>
                        {/* {!token.isBalanceLoaded && (
                          <div className="dot-flashing"></div>
                        )} */}
                        <span
                          className="tokens-list-item__currency"
                          style={{ visibility: "hidden" }}
                        >
                          $1.25
                        </span>
                        {chooseTokenState.directType != TOKEN_DIRECTION_FROM && (
                          <span className="tokens-list-item__currency">
                            {token.isLiqvid ? "liquid" : "non-liquid"}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
  );
}
