import { getNetworkImageByChainId, getTxScanUrl } from "../../utils/NetworkList";
import SwapTokenContainer from "./SwapTokenContainer";
import SwapSuccessNetwork from "./SwapSuccessNetwork";
import SwapTokenApproveBlock from "./SwapTokenApproveBlock";
import SwapTransferApproveBlock from "./SwapTransferApproveBlock";
import { BTN_KEY_RETURN_SWAP_FORM, BTN_KEY_TRANSFER_RETRY } from "../../constants/SwapButtonConstants";
import SwapChangeNetworkAlert from "./SwapChangeNetworkAlert";
import { STEP_SWITCH_NETWORK } from "../../constants/TransferConstants";
import {
  convertTransactionHash,
  copyToBuffer,
} from "../../utils/TransferApiUtil";
import SwapSuccessLinks from "./SwapSuccessLinks";

export default function TransferProcess({
  formData,
  transactionStep,
  transactionData,
  btnHandler,
  onProcessTransactions,
  curTransId,
}) {
  const process = onProcessTransactions.current.find(
    (v) => v.id == curTransId.current
  );

  return (
    <>
      <div className="transfers__header">
        <button
          className="back-btn"
          onClick={() => btnHandler(BTN_KEY_RETURN_SWAP_FORM)}
        ></button>
        <div className="transfers__title-block">
          <h1 className="transfers__title">Swap process</h1>
          {process && (process.approveTx || process.transfetTx) && (
            <div className="transfers__trx-id">
              <span>Trx ID:</span>
              <a
                href={getTxScanUrl(formData.routeFrom.chainId, process.transfetTx || process.approveTx)}
                target="_blank"
              >
              <span
              >
                {convertTransactionHash(
                  process.transfetTx || process.approveTx
                )}
              </span>
              </a>
            </div>
          )}
        </div>
        <span></span>
        {/* <span className="loader-dark"></span> */}
      </div>
      <div className="transfers__swaping-tokens">
        <SwapTokenContainer formData={formData} isFrom={true} />
        <div className="transfers__indicator">
          <div className="transfers__indicator-networks">
            <img
              src={getNetworkImageByChainId(formData.routeFrom.chainId)}
              width="14"
              height="14"
              alt=""
            />
            <img
              src={getNetworkImageByChainId(formData.routeTo.chainId)}
              width="14"
              height="14"
              alt=""
            />
          </div>
          <img
            className="transfers__arrow"
            src="./images/transfer-arrow.svg"
            width="24"
            height="16"
            alt=""
          />
        </div>
        <SwapTokenContainer formData={formData} isFrom={false} />
      </div>
      {transactionStep == STEP_SWITCH_NETWORK && (
        <SwapChangeNetworkAlert formData={formData} />
      )}
      {transactionStep != STEP_SWITCH_NETWORK && (
        <SwapSuccessNetwork formData={formData} />
      )}
      {process && (
        <SwapTokenApproveBlock
          process={process}
          btnHandler={btnHandler}
          formData={formData}
        />
      )}
      {process && (
        <SwapTransferApproveBlock
          process={process}
          btnHandler={btnHandler}
          formData={formData}
          transactionData={transactionData}
        />
      )}
    </>
  );
}
