import React from "react";
import ChooseTokenPopup from "../../../Swap/Dialogs/ChooseTokenPopup";
import NotificationPopup from "../../Popups/NotificationPopup";
// import { connectWalletuseState } from "react";
import ChooseWallet from "../ChooseWallet/ChooseWallet";
import DisconnectMetamask from "../DisconnectMetamask/DisconnectMetamask";
import WalletInfoDialog from "../WalletInfoDialog/WalletInfoDialog";

function PopupBlock() {
  return (
    <>
      {/* <DisconnectMetamask /> */}
      <ChooseWallet />
      <WalletInfoDialog />
      <NotificationPopup />
      <ChooseTokenPopup/>
    </>
  );
}
export default PopupBlock;
