import { BTN_KEY_TRANSFER_RETRY } from "../../constants/SwapButtonConstants";
import {
  STEP_TRANSFER_APPROVED,
  STEP_TRANSFER_FINISHED,
  STEP_TRANSFER_REJECTED,
  STEP_TRANSFER_START,
} from "../../constants/TransferConstants";
import { getTxScanUrl } from "../../utils/NetworkList";
import SwapSuccessLinks from "./SwapSuccessLinks";

export default function SwapTransferApproveBlock({
  process,
  btnHandler,
  formData,
  transactionData
}) {

  return (
    <>
      {!process.isTransferApproved && (
        <>
          {process.state == STEP_TRANSFER_START && (
            <div className="transfers__notification transfers__notification_proccess">
              <span className="loader-blue"></span>
              <span>Transfer transaction approve required</span>
            </div>
          )}
          {process.state == STEP_TRANSFER_REJECTED && (
            <div className="transfers__notification transfers__notification_attention">
              Transfer approve was rejected{" "}
              <a
                className="link"
                href="#"
                onClick={() => btnHandler(BTN_KEY_TRANSFER_RETRY)}
              >
                Try one more time
              </a>
            </div>
          )}
        </>
      )}
      {process.isApproved &&
        [STEP_TRANSFER_APPROVED, STEP_TRANSFER_FINISHED].includes(
          process.state
        ) && (
          <div className="transfers__notification transfers__notification_success">
            <span>Transaction approved successfully.</span>
          </div>
        )}
      {process.isTransferApproved &&
        process.state == STEP_TRANSFER_APPROVED && (
          <div className="success-message">
            <span className="loader-blue"></span>
            <span className="success-message__title">
              You will recieve in a few minutes
            </span>
            <SwapSuccessLinks formData={formData}  process={process} btnHandler={btnHandler} transactionData={transactionData}></SwapSuccessLinks>
          </div>
        )}
      {process.isFinished && process.state == STEP_TRANSFER_FINISHED && (
        <div className="success-message">
          <span className="success-message__title">You will soon receive</span>
          
          <SwapSuccessLinks formData={formData}  process={process} btnHandler={btnHandler} transactionData={transactionData}></SwapSuccessLinks>
        </div>
      )}
    </>
  );
}
