export const MIN_USD_AMOUNT = 20;
export const CHECK_MIN_AMOUNT = false;

export const TOKEN_DIRECTION_FROM = "From";

export const STEP_FORM_FILL = 0;
export const STEP_LOAD_QUOTA = 1;
// export const STEP_READY_START_SWAP = 2;
export const STEP_UPDATE_RATES = 2;
export const STEP_READY_PREVIEW = 3;
export const STEP_READY_APPROVE = 4;
export const STEP_APPROVE_STARTED = 5;
export const STEP_APPROVE_APPROVED = 6;
export const STEP_APPROVE_REJECTED = 7;
export const STEP_APPROVE_FINISHED = 8;
export const STEP_TRANSFER_START = 9;
export const STEP_TRANSFER_APPROVED = 10;
export const STEP_TRANSFER_REJECTED = 11;
export const STEP_TRANSFER_FINISHED = 12;
export const STEP_SWITCH_NETWORK = 13;
export const STEP_FORM_NO_ROUTE_FOUND = 14;

export const PROCESS_FIND_ROUTE = 1;
export const PROCESS_SWAP_ROUTE = 2;

export const DEFAULT_TOKEN_SYMBOL = "USDT";
export const DEFAULT_TOKEN_SYMBOL2 = "USDC";

export const STATUS_APPROVED = "approved";
export const STATUS_IN_PROGRESS = "in_progress";
export const STATUS_FINISHED = "finished";
export const STATUS_REJECTED = "rejected";

export const GAS_AMOUNT_USD = 3;
export const GAS_AMOUNT_USD_ETHER = 10;

export const amountErrorText = "Amount too small";
export const amountWarning = "Open beta - minimum transaction amount is 50$";

export const ETH_CHAIN_ID = "1";
export const BSC_CHAIN_ID = "56";

export const MAX_PENDING_COUNT = 3;
export const MAX_COMPLETED_COUNT = 2;
export const MAX_COUNT_PER_PAGE = 5;

export const MAX_ROUTE_REFRESH_TIME = 60; //seconds

export const POPULAR_TOKENS = [
  "USDT",
  "USDC",
  "BUSD",
  "DAI",
  "TUSD",
  "USDP",
  "GUSD",
  "sUSD",
  "FRAX",
  "ETH",
  "BNB",
  "AVAX",
  "WBTC",
  "WETH",
  "MATIC",
];

export const SIGN_TEXT = `I am not the person or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America or any Prohibited Localities, as defined in the Terms of Use.
I will not in the future access this site or use Chainspot dApp while located within the United States any Prohibited Localities, as defined in the Terms of Use.
I am not using, and will not in the future use, a VPN to mask my physical location from a restricted territory.
I am lawfully permitted to access this site and use Chainspot dApp under the laws of the jurisdiction on which I reside and am located.
I understand the risks associated with entering into using Chainspot Network protocols.
`;