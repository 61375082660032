export default function SwapDetailNoRoute() {
  return (
    <div className="widget__info">
      <img src="img/routing-icon.svg" alt="" />
      <span>
        The best route option
        <br />
        will be displayed here
      </span>
    </div>
  );
}
