import { getNetworkImageByChainId, getTokenImg } from "../../utils/NetworkList";

export default function SwapTokenContainer({ formData, isFrom }) {
  return (
    <div className="token-cnt column">
      <div className="token-cnt__icon">
        <img
          className="token-cnt__icon-token"
          src={getTokenImg(isFrom ? formData.cryptoFrom : formData.cryptoTo)}
          width="32"
          height="32"
          alt=""
        />
        <img
          className="token-cnt__icon-network"
          src={getNetworkImageByChainId(
            isFrom ? formData.routeFrom.chainId : formData.routeTo.chainId
          )}
          width="14"
          height="14"
          alt=""
        />
      </div>
      <div className="token-cnt__info">
        <span className="token-cnt__value">
          {isFrom ? formData.amountFrom : formData.amountTo}{" "}
          {isFrom ? formData.cryptoFrom.symbol : formData.cryptoTo.symbol}
        </span>
        <div className="token-cnt__caption">
          <span>${isFrom ? formData.amountFromUSD : formData.amountToUSD}</span>
          <span>
            on&nbsp;{isFrom ? formData.routeFrom.name : formData.routeTo.name}
          </span>
        </div>
      </div>
    </div>
  );
}
