import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../../../features/dialogs/notificationPopupSlice";

export default function NotificationPopup() {
  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );
  const dispatch = useDispatch();

  if(!notificationState.show){
    return;
  }
  return (
    <>
      <div className="warning-popup service-popup">
        {notificationState.title && (
          <h2 className="service-popup__title">{notificationState.title}</h2>
        )}
        <button
          className="service-popup__close"
          onClick={() => dispatch(closeNotification())}
        ></button>

        <div className="service-popup__icon service-popup__icon_warning">
          <img src="/img/popup-warning-icon.svg" alt="" />
        </div>

        <p className="service-popup__text service-popup__text_gray">
        {notificationState.caption}
        </p>

      </div>
   </>
  );
}
