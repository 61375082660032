import React, { useEffect, useRef, useState } from "react";
import {
  agreementDialogToggle,
  connectWallet,
  showAgreementDialog,
  walletTypeDialogToggle,
} from "../../../../features/walletService/walletService";
import { useDispatch, useSelector } from "react-redux";
import { WalletList } from "../../../../utils/NetworkList";
import ChooseWalletItem from "./ChooseWalletItem";
// import Cookies from 'universal-cookie';

function ChooseWallet() {
  const dispatch = useDispatch();
  let showWalletTypePopup = useSelector(
    (state) => state.walletServiceProvider.showWalletTypePopup
  );
  let connectingWallet = useSelector(
    (state) => state.walletServiceProvider.connectingWallet
  );

  const walletServiceList = WalletList;

  const onWalletSelect = (walletKey) => {
      dispatch(connectWallet(walletKey));
  };

  return (
    (showWalletTypePopup && <div
      className={
        "widget-popup connect-wallet-popup "
      }
    >
      <div className="widget-popup__header">
        <button className="widget-popup__back-btn" onClick={() => dispatch(walletTypeDialogToggle())}>Back</button>
        <h3 className="widget-popup__title">Connect wallet</h3>
      </div>
      <div className="connect-wallet-popup__wallets-list">
        {walletServiceList &&
          walletServiceList.map((walletService) => (
            <ChooseWalletItem
              key={walletService.key}
              walletService={walletService}
              onSelect={onWalletSelect}
            />
          ))}
      </div>
    </div>
    )
  );
}
export default ChooseWallet;
