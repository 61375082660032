import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { walletDisconnectDialogToggle } from "../../../../features/walletService/walletService";

function DisconnectMetamask () {
    let showWalletDisconnectPopup = useSelector(state => state.walletServiceProvider.showWalletDisconnectPopup)
    let dispatch = useDispatch()

    return(
      <div className={"choose-wallet-popup popup "+ (showWalletDisconnectPopup ? 'active' : '') }>
      <div className="popup__container">
        <a className="popup__close" onClick={() => dispatch(walletDisconnectDialogToggle())}></a>
        <h1 className="choose-wallet-popup__title popup__title">Disconnect to a Wallet</h1>
        <p>In order to fully disconnect your wallet and to withdraw all given permissions, please open your wallet.
          </p>
              <img style={{height: '300px'}} src="./images/metamask_disconnect.9b2d3abf.gif" alt=""/>
      </div>
    </div>
      );
}
export default DisconnectMetamask;
