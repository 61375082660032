import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BTN_KEY_APPROVE,
  BTN_KEY_CHAIN_CHANGE,
  BTN_KEY_PREVIEW,
  BTN_KEY_UPDATE_RATE,
} from "../../constants/SwapButtonConstants";
import {
  amountErrorText,
  STEP_FORM_FILL,
  STEP_FORM_NO_ROUTE_FOUND,
  STEP_LOAD_QUOTA,
  STEP_READY_APPROVE,
  STEP_READY_PREVIEW,
  STEP_SWITCH_NETWORK,
  STEP_UPDATE_RATES,
} from "../../constants/TransferConstants";
import { walletTypeDialogToggle } from "../../features/walletService/walletService";
import { getNetworkNameByChainId } from "../../utils/NetworkList";
import { isAmountEmpty, isAmountLess } from "../../utils/TransferApiUtil";

export default function SwapButton({
  transactionStep,
  formData,
  tokenBalance,
  btnHandler,
}) {
  const notificationState = useSelector(
    (state) => state.notificationPopupManager
  );

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  let connectingWallet = useSelector(
    (state) => state.walletServiceProvider.connectingWallet
  );

  const dispatch = useDispatch();

  const isOverBalance = useMemo(() => {
    if (notificationState.show && notificationState.caption.startsWith(amountErrorText)) {
      return true;
    }
    if (!walletInfo.isConnected) {
      return false;
    }
    if (isAmountEmpty(formData.amountFrom)) {
      return false;
    }
    if (isAmountLess(formData.amountFromUSD)) {
      return true;
    }
    return parseFloat(formData.amountFrom) > parseFloat(tokenBalance);
  }, [
    formData.amountFrom,
    formData.amountFromUSD,
    tokenBalance,
    walletInfo.isConnected,
    notificationState,
    amountErrorText,
  ]);

  return (
    <>
      {!walletInfo.isConnected && (
        <button
          type="button"
          className="widget-form__submit"
          onClick={() => dispatch(walletTypeDialogToggle())}
        >
          {connectingWallet && <span className="loader"></span>}
          Connect your wallet
        </button>
      )}
      {walletInfo.isConnected &&
        transactionStep == STEP_FORM_FILL &&
        !isOverBalance && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => {}}
            disabled
          >
            Swap
          </button>
        )}
      {walletInfo.isConnected &&
        transactionStep == STEP_FORM_NO_ROUTE_FOUND && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => {}}
            disabled
          >
            Change destination token
          </button>
        )}
      {walletInfo.isConnected &&
        transactionStep == STEP_LOAD_QUOTA &&
        !isOverBalance && (
          <button className="widget-form__submit">
    				<span className="loader"></span>
            Searching Routes
          </button>
        )}
      {walletInfo.isConnected &&
        formData.routeFrom &&
        transactionStep == STEP_SWITCH_NETWORK && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => btnHandler(BTN_KEY_CHAIN_CHANGE)}
          >
            Switch network to{" "}
            {getNetworkNameByChainId(formData.routeFrom.chainId,formData.routeFrom.name)}
          </button>
        )}
      {walletInfo.isConnected &&
        !isOverBalance &&
        transactionStep == STEP_READY_PREVIEW && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => btnHandler(BTN_KEY_PREVIEW)}
          >
            Review swap
          </button>
        )}
      {walletInfo.isConnected &&
        !isOverBalance &&
        transactionStep == STEP_READY_APPROVE && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => btnHandler(BTN_KEY_APPROVE)}
          >
            Swap
          </button>
        )}
        {walletInfo.isConnected &&
        !isOverBalance &&
        transactionStep == STEP_UPDATE_RATES && (
          <button
            type="button"
            className="widget-form__submit"
            onClick={() => btnHandler(BTN_KEY_UPDATE_RATE)}
          >
            Update rates
          </button>
        )}
      {walletInfo.isConnected &&
        ![STEP_SWITCH_NETWORK, STEP_FORM_NO_ROUTE_FOUND].includes(
          transactionStep
        ) &&
        isOverBalance && (
          <button
            type="button"
            disabled
            className="widget-form__submit"
          >
            Insufficient Funds
            <br /> on departure Chain
          </button>
        )}
    </>
  );
}
