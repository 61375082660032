export default function SwapNoRouteFound() {
  return (
    <div className="widget__info" style={{alignItems: "center"}}>
      <img src="img/no-routes-icon.svg" alt="" />
      <div>
        <h3 className="widget__info-title">No routes availaible</h3>
        <span>Try another token combination</span>
      </div>
    </div>
  );
}
